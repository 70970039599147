import '../../index.css';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';



function Education() {

  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H0L89HEMKP';
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-H0L89HEMKP');
    };

    // Cleanup script tag on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const courses = [
    {
      id: 1, title: 'Course 1', image: '/images/courses/course1.jpeg'

    },
  ]
  const [show, setShow] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleClose = () => setShow(false);

  const openModal = (o) => {
    setSelectedCourse(o);
    setShow(true);
  };

  return (
    <div>
      <Helmet>
        <title>American Academy Of Cosmetic Surgery Hospital Academy</title>
        <meta name="description" content="At AACSH, we are committed to providing exceptional educational opportunities to enhance your expertise and further your career in various medical fields." />
        {/* Add more meta tags if needed */}
      </Helmet>
      <section id="about" class="about" style={{ marginTop: "90px" }}>
        <div class="container-fluid">
          {/* <div class="row" style={{ marginBottom: "15px" }}><div class="col-xl-12 col-lg-12  d-flex justify-content-center align-items-stretch position-relative"><h3 style={{ fontSize: "28px", fontWeight: "700", color: "#2c4964" }}>Welcome To The AACSH Academy</h3></div></div> */}
          <div class="row">
            <div class="col-xl-5 col-lg-6  d-flex justify-content-center align-items-stretch position-relative py-2">
              <img src="images/heroes/6.jpg" alt="" className="img-fluid" style={{ maxHeight: "600px" }} />


            </div>

            <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch py-y px-lg-2">
            <h3 style={{ fontSize: "28px", fontWeight: "700", color: "#2c4964" }}>Welcome To The AACSH Academy</h3>

              <p>Welcome to THE ACADEMY at the American Academy of Cosmetic Surgery Hospital, your premier destination<br></br> for cutting-edge education and continuous medical training in Dubai. As an approved practice setting approved <br></br>by Dubai Health Authority and Dubai Health Care Authority, we are committed to providing exceptional<br></br> educational opportunities to enhance your expertise and further your career in various medical fields, including<br></br> Esthetic Medicine, Plastic Surgery, Rhinoplasty, Bariatric Surgery, Dermatology, Gynecology, and Dentistry. </p>
              <p>Our goal is to empower healthcare professionals with the knowledge, skills, and experience needed to excel in<br></br> their respective fields.</p>
              <p>Our state-of-the-art facility is equipped with two educational halls, each capable of hosting up to 40 delegates.<br></br> These halls are designed to create an optimal learning environment for participants, fostering interaction and<br></br> collaboration. In addition, THE ACADEMY boasts an education operating room (OT) that provides a realistic setting<br></br> for practical training, ensuring that participants gain the experience and skills needed to excel in their chosen fields.</p>
              <p>As a testament to our commitment to excellence, THE ACADEMY is proud to be associated with the International Association of Dermatology and Aesthetic Medicine (IADA). This affiliation allows us to stay at the forefront of advancements in the field, ensuring that our educational programs are aligned with the latest international standards.</p>
              <p>THE ACADEMY currently hosts interns and residencies, which are not only approved but also recommended by the <br></br>Dubai Health Authority (DHA). These programs provide participants with invaluable hands-on experience, allowing<br></br> them to apply theoretical knowledge in real-world medical scenarios.</p>
              <p>Whether you are looking to fulfill internal privileges, graduate from extensive programs, or simply enhance your professional journey, THE ACADEMY at the American Academy of Cosmetic Surgery Hospital is the ideal destination<br></br> for your educational endeavors. </p>
              <p>Join us as we pave the way for excellence in medical education, setting new standards in healthcare training.</p>


            </div>
          </div>
          
          <div class="row" style={{ marginTop: "45px" }}><div class="col-xl-12 col-lg-12  d-flex justify-content-center align-items-stretch position-relative"><h3 style={{ fontSize: "28px", fontWeight: "700", color: "#2c4964" }}>Courses</h3></div></div>
          <div class="row" style={{ marginTop: "30px" }}>
            <div class="col-xl-12 col-lg-12  d-flex justify-content-center align-items-stretch position-relative">
              <p style={{marginLeft: "100px",  marginRight: "50px"}}>At the American Academy of Cosmetic Surgery Hospital, we pride ourselves on the extensive variety of courses
                we offer, tailored to meet the diverse needs and interests of our students and professionals aspiring to excel in
                their field. Our curriculum is designed to provide comprehensive knowledge and hands-on experience, ensuring
                that our participants are well-equipped with the latest techniques and best practices.<br></br> <br></br>Whether you're looking to
                start your journey in this exciting field or aiming to upgrade your skills and expertise, our courses are curated to
                cater to various levels of proficiency and specializations. For those interested in exploring the vast opportunities
                our academy has to offer and wish to learn more about our programs, detailed information is readily available.
                <br></br><br></br>We encourage prospective students and professionals to reach out and contact us at +971 50 104 4459.
                Our dedicated team is on hand to answer any queries you may have and guide you through the enrollment process, helping you take the first step towards achieving your career goals.

              </p>
              
            </div>
          </div>
          <div class="row" style={{ marginTop: "30px", justifyContent: "center" }}>
            <div class="col-lg-4">
              <img src="images/courses/course6.jpeg" alt="" style={{ maxHeight: "480px", width: "400px" }} />

            </div>
            <div class="col-lg-4">
              <img src="images/courses/course5.jpeg" alt="" style={{ maxHeight: "480px", width: "400px" }} />

            </div>
            <div class="col-lg-6">
              <video width="750" height="500" controls style={{ marginTop: "10px" }}>
                <source src="videos/1.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

        </div>
      </section>
      {/* {selectedCourse && (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>


            <img src="/images/courses/course1.jpeg" style={{ width: "100%", marginBottom: "50px" }} />
            <img src="/images/courses/course2.jpeg" style={{ width: "100%", marginBottom: "50px" }} />
            <img src="/images/courses/course3.jpeg" style={{ width: "100%" }} />



          </Modal.Body>

        </Modal>
      )} */}
    </div>
  )
}

export default Education