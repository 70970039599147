import '../../index.css';
import SpecialitiesList from '../SpecialitiesList/SpecialitiesList';
import React, { useState, useEffect } from 'react';

function Specialities({ service }) {


  
  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H0L89HEMKP';
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-H0L89HEMKP');
    };

    // Cleanup script tag on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const featuresData = [


    {
      imgSrc: 'images/services/Endocrinology.jpg',
      alt: 'Dentistry',
      icon: 'FaFlask',
      title: 'FUE HAIR TRANSPLANT',
      desc: "AACSH integrates medical knowledge and extensive care to treat diabetes and other endocrine-related disorders, having one of the best diabetes specialists in Dubai At AACSH our Endocrinologists and Diabetes specialists use advanced technology and innovative equipment, which add to the long-term commitment to patient care. The team renders comprehensive care and non-invasive diagnostic services to all the patients. The Department offers expert consultation, counseling, diagnosing the various problems early, and treating them with great skill and experience."
    },
    // {
    //   imgSrc: 'images/services/ENT.jpg',
    //   alt: 'Dentistry',
    //   icon: 'FaHeadSideCough',
    //   title: 'ENT',
    //   desc: "Ear, Nose, and Throat (ENT), also known as Otolaryngology, is a medical specialty that focuses on the diagnosis, treatment, and management of disorders and conditions related to the ears, nose, throat, and related structures of the head and neck. Otolaryngologists, or ENT specialists, are medical doctors who specialize in this field and provide comprehensive care for a wide range of issues affecting these areas."
    // },
    {
      imgSrc: 'images/services/dermatology.jpg',
      alt: 'Dentistry',
      icon: 'FaRegSun',
      title: 'COSMETIC SURGERY',
      desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
    },

    {
      imgSrc: 'images/services/CosmeticSurgery.jpg',
      alt: 'Dentistry',
      icon: 'FaSyringe',
      title: 'DERMATOLOGY & ANTI AGING',
      desc: "At the American Academy of Cosmetic Surgery Hospital we offer a range of cutting-edge cosmetic surgery procedures designed to enhance your natural beauty and boost your self-confidence. Our team of highly skilled and experienced surgeons is dedicated to providing you with personalized care and achieving your aesthetic goals."
    },
    {
      imgSrc: 'images/services/Bariatric.jpg',
      alt: 'Dentistry',
      icon: 'FaAssistiveListeningSystems',
      title: 'HAIR LOSS',
      desc: "Obesity is a complex medical condition characterized by excessive body weight due to the accumulation of excess fat. It can lead to various health complications such as diabetes, cardiovascular disease, joint issues, and more. Bariatric services aim to help individuals manage and overcome obesity through a combination of lifestyle changes, dietary modifications, and, in some cases, surgical procedures.AACSH provides ethical, evidence- based obesity management programs according to recognized international standards of patient care.The multi-disciplinary approach program offers the following services:"
    },
    {
      imgSrc: 'images/services/gynecology.jpg',
      alt: 'Dentistry',
      icon: 'FaVenus',
      title: 'COSMETIC GYNECOLOGY',
      desc: "Every woman deserves to feel confident and comfortable in her own body.We offer a comprehensive range of surgical and non-surgical treatments specifically designed to address the unique needs and concerns of women seeking cosmetic gynecology procedures.Our team of highly skilled and experienced cosmetic gynecologists are dedicated to providing the highest standard of care in a compassionate and supportive environment."
    },

    {
      imgSrc: 'images/services/Dietetics.png',
      alt: 'Dietetics',
      icon: 'FaComments',
      title: 'PSYCHOLOGY',
      desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
    },
    {
      imgSrc: 'images/services/Dietetics.png',
      alt: 'Dietetics',
      icon: 'FaWeight',
      title: 'WEIGHT LOSS & NUTRITION',
      desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
    },
    {
      imgSrc: 'images/services/Dietetics.png',
      alt: 'Dietetics',
      icon: 'FaSpa',
      title: 'MEDICAL SPA',
      desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
    },
    {
      imgSrc: 'images/services/Dietetics.png',
      alt: 'Dietetics',
      icon: 'FaUtensils',
      title: 'ENDOCRINOLOGY',
      desc: "Dermatology is a medical specialty that focuses on the study, diagnosis, treatment, and prevention of conditions and diseases related to the skin, hair, nails, and mucous membranes. Dermatologists are medical doctors who specialize in dermatology and provide comprehensive care for a wide range of skin-related issues. The skin is the body's largest organ and plays a vital role in protecting the body from external factors, regulating temperature, and serving as a barrier to infection."
    },
    {
      imgSrc: 'images/services/Dietetics.png',
      alt: 'Dietetics',
      icon: 'FaBone',
      title: 'ORTHOPEDIC ',
      desc: "At AACSH, we understand the importance of mobility and freedom in your daily life. Our advanced orthopedic services are designed to provide comprehensive care and support for a wide range of orthopedic conditions. From fracture management to joint replacements and specialized pediatric care, our team is committed to ensuring your journey to recovery is as painless as possible. With a focus on the latest medical advancements and a compassionate approach, we strive to help you regain the full function of your bones and joints. Trust AACSH for expert orthopedic care that puts your mobility and well-being first."
    },
  ];


  const [activeTab, setActiveTab] = useState(service);
  return (
    <div>

      <section id="departments" className="departments" style={{ marginTop: "100px" }}>
        <div className="container">

          <div className="section-title">
            <h2 style={{ color: "#2c4964" }}>Specialities</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-3">
              <ul className="nav  flex-column" style={{ listStyleType: "none" }} >
                {featuresData
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((feature, index) => (
                    <li key={index} className="nav-item">
                      <a
                        className={`nav-link ${index === activeTab ? 'active show' : ''}`}
                        data-bs-toggle="tab"
                        href={`#tab-${index + 1}`}
                      >
                        {feature.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                {featuresData.map((feature, index) => (
                  <div key={index} className={`tab-pane ${index === activeTab ? 'active show' : ''}`} id={`tab-${index + 1}`}>
                  <div className="row gy-4">
                      <img src={feature.imgSrc} alt={feature.alt} className="img-fluid" style={{ width: "50%" }} />
                      <h3 style={{ color: "#2c4964" }}>{feature.title}</h3>
                      <p >{feature.desc}</p>

                      {feature.listService && feature.listService.length > 0 && (
                        <div>

                          <ul>
                            {feature.listService.map((item, itemIndex) => (
                              <li key={itemIndex}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </section>

    </div>
  )
}
export default Specialities