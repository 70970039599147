import React, { useEffect, useState } from 'react';
import Appointment from '../Appointment/Appointment';
import SuccessModal from '../SuccessModal/SuccessModal';
import { useParams } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
function DoctorProfile(props) {

    const { doctor } = useParams();
    const doctors = [
        {
            id: 1, name: 'Dr. Amr Majzoub', image: '/Doctors/Majzoub.jpeg', speciality: 'Anesthesia',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Anesthesia": [],
                "Critical care medicine": [],
                "Interventional pain management": [],

            },
            nationality: 'Syrian',
            summary: "Dr.Majzoub has 8 years of experience in all aspects of anesthesia (sedation, general anesthesia, and regional anesthesia). Experience in critical care medicine for life-saving and close monitoring of high risk patients. Experience in minimally invasive non-surgical interventions to relieve chronic pain associated with herniated discs or musculoskeletal injuries.",
            qualifications: [
                "Master of Science in Anesthesia, Critical Care, and Pain Management, Damascus University.",
                "Syrian board in anesthesia and reanimation",
            ]
        },
        {
            id: 2, name: 'Dr. Atif Fahim Mina', image: '/Doctors/Fahim.jpeg',
            speciality: 'Anesthesia', languages: ['Arabic', 'English'],
            "coreCompetencies": {
                "Administering general, spinal, epidural, and local anesthesia and sedation for all cases related to general surgery, gynecology and obstetrics, urology, pediatrics, faciomaxillary, ophthalmology, neurology, orthopedics, ENT, endoscopic surgery, plastic surgery, and dental surgery for children and adults.": [],
                "Orders diagnostic tests as needed.": [],
                "Works closely with surgical specialists.": [],
                "Mentors and supervises anesthesiology interns, residents, and fellows.": [],
                "Determines when a patient has recovered from the effects of anesthesia.": [],
                "Creating anesthesia plans for each patient.": [],
                "Communicating with patients and families throughout the entire surgical process, including preparation and recovery.": [],
                "Mentoring medical students and residents on various procedures.": [],
                "Maintaining comprehensive records of medical procedures and patient care.": []
            },
            summary: "Dr.Atif Mina has more than 50 years of experience in all aspects of anesthesiology, mainly in UAE",
            qualifications: [
                "Specialist Anesthetist",
                "Masters of Science Degree in Anesthesia, Cairo University Medical School,Egypt",
                "Diploma in Anesthesia (DA), Cairo University Medical School , Egypt",
                "MB.B.CH.,Ain Shams Medical School, Egypt",
            ]
        },
        {
            id: 3, name: 'Dr. Noor Sheema Ghazi', image: '/Doctors/Ghazi.jpeg', speciality: 'Anesthesia', languages: ['Hindi', 'English', 'Urdu'],
            coreCompetencies: {
                "Regional blocks": [],
                "Labour Analgesia": [],
                "USG guided lines insertion": []
            },
            nationality: 'Indian',
            summary: "",
            qualifications: [
                "Basic Life Support (BLS) and Advanced Cardiac Life Support (ACLS)",
                "Acute Trauma Life Support (ATLS)",
                "Fundamental Critical Care Support (FCCS)",
                "Pain Management (Sir Ganga Ram Hospital, New Delhi)",
                "Member in the Organizing Committee of the Pain Management and Airway National Conference (Delhi chapter).",
                "Member in the Organizing Committee of the Indian Society of Anesthesiologists, Delhi Chapter 2013.",
                "Member of the Emergency team to provide relief for the bomb blast victims in New Delhi, India",
            ]
        },
        {
            id: 5, name: 'Dr. Diyaaldeen Mohammed',
            image: '/Doctors/Diyaaldeen.jpeg',
            speciality: 'General & Bariatric Surgery',
            languages: ['English', 'Arabic'],
            coreCompetencies: {
                "Specialist Obesity , Laparoscopic & General Surgeon": [],
                "Certified in Bariatric Surgery and Revisional Bariatric Surgery, Switzeland": [],
                "USG guided lines insertion": []
            },
            nationality: '',
            option: "Medical Director",
            summary: "",
            qualifications: [
                "More than 17 years of professional experience with 2000+ surgeries.",
            ]
        },
        {
            id: 35, name: 'Ms. Jessica Khalife',
            image: '/Doctors/jessi.png',
            speciality: 'Clinical Dietitian',
            languages: ['Arabic', 'English', 'French'],
            summary: "Licensed Clinical Dietitian with experience excelling in patient education and medical nutritional therapy. Expert in crafting individualized meal plans and enhancing patient outcomes through evidence-based advice and nutrition counseling. Demonstrates exceptional verbal communication and interdisciplinary collaboration, significantly improving nutrition care processes.",
            nationality: 'Lebanese',
            qualifications: [
                "2018 Bachelor of Science, Nutrition And Dietetics , Lebanese University , Lebanon",
                "2020 Master of Science, Clinical And Public Health Nutrition , Lebanese University , Lebanon"
            ],
            "coreCompetencies": {
                "Patient Education": [],
                "Nutrition counseling": [],
                "Medical Nutrition Therapy": [],
                "Individualized support": [],
                "Behavior Change Techniques": [],
                "Dietary planning": [],
                "Nutrition needs assessment": [],
                "Menu development": [],
                "Health Education": [],
                "Cooking Instructions": [],
                "Evidence-based advice": [],
            }

        },
        {
            id: 6, name: 'Dr. Said Hassan', image: '/Doctors/Hassan.jpeg',
            speciality: 'General & Bariatric Surgery', languages: ['English', 'Arabic'],
            coreCompetencies: {
                "Bariatric Surgery": [],
                "Laparoscopic Sleeve Gastrectomy": [],
                "Gastric Bypass": [],
                "Non-Surgical Weight Loss Procedures": [],

            },
            nationality: "Syrian",
            summary: "Syrian Board Certified in General Surgery, Fellowship in laparoscopic surgery (FMAS), Extensive experience in bariatric surgery, including laparoscopic sleeve gastrectomy, gastric bypass, and non-surgical weight loss procedures, Surgeon in the American Academy for Cosmetic Surgery Hospital",
            qualifications: [
                "Bachelor of Medicine and Surgery (MBBS) from Damascus University, Syria",
                "The Head of the Surgery Department at CosmoHealth Medical Centers",
                "Advanced certificate in clinical education from the Royal College of Surgeons in Glasgow",
                "Previously served as an Adjunct Assistant Professor in RAKMHSU (Surgery)",
                "In the final stage of the MSc in Leadership and Innovation in Healthcare"
            ]

        }, {
            id: 7, name: 'Dr. Alexander Bader', image: '/Doctors/Bader.jpeg',
            speciality: 'Cosmetic Gynecology', languages: ['English', 'Greek'],
            "coreCompetencies": {
                "Labiaplasty": [],
                "Laser vaginal rejuvenation": [],
                "Urinary incontinence": [],
                "Vaginal prolapse": [],
                "Vaginoplasty": [],
                "Rectocele": [],
                "Pelvic floor Reconstructive Surgery": [],
                "Female urine Incontinence": [],
                "Cosmetic gynaecology": [],
                "Aesthetic gynaecology": []
            },
            nationality: 'England',
            summary: "Dr Alexander Bader is a London-based internationally renowned specialist OB/GYN and cosmetic gynaecologist who pioneered cosmetic vaginal surgery (CVS) in Europe. Dr Bader is the first doctor on the continent to exclusively practice cosmetic vaginal surgery. He is a member of the American Academy of Cosmetic Surgery (AACS), the American Academy of Cosmetic Gynaecologists (AAOCG), and the International Society of Cosmetogynecology (ISG). He is regularly invited to lecture and teach at international meetings in cosmetic surgery. His exclusive involvement with reconstructive and cosmetic vaginal surgery for the last decade affords him the opportunity to deal with a large volume of interesting and complex cases on a daily basis. Dr Bader has performed more than 3000 cases in his file. Dr Bader frequently conducts hands-on training courses and holds the position of President of The European Society of Aesthetic Gynaecology (ESAG). He leads a large number of enthusiastic doctors from all over the world who want to be positively involved with this innovative field. Dr Bader is the founder of Bader Medical Institute of London. He divides his time between London and Dubai, where he is a visiting surgeon in several prestigious clinics and hospitals.",
            qualifications: [
                "Medical School1996 University of Ioannina",
                "Maternity clinic, General Hospital Athens 'Elenas Venizelou'",
                "Kiel School Of Laparoscopy, Christian-Albrechts Holstein Universitat, Germany",
                "S.C.U Hospital, Los Angeles, California, U.S.A",
                "P. Cosmetogynecology Centre, New Jersey, U.S.A",
                "Diploma, American Academy Of Cosmetic Gynecology (AAOCG)",
                "Qualified in 'Infertility Treatment', University KIEL Germany"
            ]

        },
        {
            id: 8, name: 'Dr. Ayman Helmi',
            image: '/Doctors/Helmi.jpeg',
            speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "The Face": [
                    "Face Lift",
                    "Neck Lift",
                    "Eyelids (Blepharoplasty)",
                    "Cheeks Augmentation",
                    "Otoplasty (Ear Reshaping)",
                    "Fat Transfer/Fat Injection",
                    "Injectables",
                    "Fillers",
                    "Botox",
                    "Platelet rich plasma (PRP)",
                    "Nefertiti - neck rejuvenation"
                ],
                "The Body": [
                    "Body Contouring after weight loss",
                    "Liposuction",
                    "Liposculpture",
                    "Arm Lift",
                    "Arm Liposuction",
                    "Abdominoplasty (Tummy Tuck)",
                    "Lipoabdominoplasty",
                    "Buttock Lift",
                    "Buttock Enhancement",
                    "Thigh Lift"
                ],
                "The Breast": [
                    "Breast Lift",
                    "Breast Augmentation",
                    "Breast Reduction",
                    "Breast Reconstruction",
                    "Male Breast Reduction (Gynecomastia)",
                    "Male Breast Lift"
                ],
                "The Lips": [
                    "Augmentation",
                    "Contouring",
                    "Smoothing",
                ],
            },
            nationality: 'Saudi arabia',
            summary: "Plastic Surgeon, Canadian Board Certified, Consultant and Director of Plastic & Burn Surgery Department, Prince Sultan Military Medical City, KSA Director of Postgraduate Medical Education & Scholarships, Riyadh Military Hospital, KSA (Past) Director of Training Plastic Surgery, Riyadh Military, KSA (Past)",
            qualifications: [
                "International Society of Aesthetic Plastic Surgery",
                "Canadian Society of Plastic Surgery"
            ]
        },
        {
            id: 9, name: 'Dr. Abdulaziz Alrasheed',
            image: '/Doctors/Alrasheed.jpeg', speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English', 'German'],
            "coreCompetencies": {
                "Body lift & contouring after rapid weight-loss surgeries": [],
                "Body lift & contouring after rapid weight-loss surgeries": [],
                "Belt lipectomy & butt lift with self-filling & without a complete wound in the back": [],
                "4D body sculpting procedures & muscle augmentation": [],
                "Arms, buttocks, upper back, abdomen & thighs lift": [],
                "Waist contouring": [],
                "Mommy Makeover Operation": ["Tummy tuck", "Fat reduction", "Butt augmentation", "Breast augmentation"],
                "All types of Liposuction": ["Fat injection and filling Women breast operations (enlarging, reduction & lifting)", "Male breast reduction/enhancement surgery", "Botox & filler injections", "Nose, face & eyelid surgery"]
            },
            nationality: 'Kuwait',
            summary: "More than 18 years of professional experience",
            qualifications: [
                "Consultant – Plastic Surgery",
                "German Board Certified"
            ]
        },
        {
            id: 10,
            name: 'Dr. Ahmad Alaslawi',
            image: '/Doctors/Alaslawi.jpeg',
            speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English', 'French'],
            "coreCompetencies": {
                "Body contouring": [],
                "Body contouring": [],
                "Liposuction – Liposculpture": [],
                "Arm lift": [],
                "Arm liposuction": [],
                "Abdominoplasty (Tummy tuck)": [],
                "Lipoabdominoplasty": [],
                "Thigh lift": [],
                "Buttock augmentation": [],
                "Buttock fat grafting (Brazilian butt)": [],
                "Eyelid lift (Blepharoplasty)": [],
                "Eyebrow lifting": [],
                "Lip reconstruction or lifting": [],
                "Neck lipoaspiration & neck lift": [],
                "Otoplasty (Ear reshaping)": [],
                "Face lift": [],
                "Rhinoplasty": [],
                "Breast lift & breast augmentation": [],
                "Breast reduction": [],
                "Breast reconstruction": [],
                "Male breast excision (Gynecomastia)": []
            },
            nationality: 'Kuwait',
            summary: "Consultant – Plastic Surgery / French Board Certified ",
            qualifications: [
                "More than 15 years of professional experience",
            ]
        },
        {
            id: 11, name: 'Dr. Ibrahim Ashary', image: '/Doctors/Ashary.jpeg', speciality: 'Cosmetic & Plastic Surgery',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Specialist in Plastic Reconstructive and Hand Surgery": [],
                "Facioplastic Surgery and Laser": [],
            },
            nationality: 'Saudi Arabia',
            summary: "I am Saudi Arab physician. Specialist in Plastic Reconstructive and Hand Surgery since September 2005, with subspecialty in Facioplastic Surgery and Laser.",
            qualifications: [
                "Universitatsklinikum Tubingen Clinic for General Surgery, Prof. Dr. H.C.H.D.",
                "Berufsgenossenschaftliche Unfallklinik Tubingen, Clinic for Trauma, Hand surgery and Reconstructive Surgery, Prof. Dr. Hans- Eberhard Schaller",
                "Universitatsklinikum Tubingen, Clinic for ENT, Prof. Dr. H.P. Zenner",
                "Universitatsklinikum Tubingen Clinic for Dermatology Surgical Department, Prof. Brunninger",
                "Marien Hospital Stuttgart Center for Plastic Surgery and Facioplastic Surgery, Prof. Dr. W. Gubisch",
                "Marien Hospital Stuttgart Clinik for Anasthesiologic and Operative Intensivmedizin (Intensive Medicine) Chefarzt Dr. Med W. Junginger",
                "Caritas- Krankenhaus St. Josef Klinik für Plastische, Hand und wiederherstellungschirurgie (Breast Center) Breast operation and Reconstructive Dr. Marita Eisenmann- Klein",
                "Experience in Saudi Arabia Pre-Plastic Surgery Specialty General Practitioner at Saudi Aramco Health Center. Physician in the Development Program for Saudi Arab General Practitioners at Saudi Aramco’s Dhahran H.C., 29-12-1990 to 31-10-1996. Rotation in Surgical, Pediatrics, General Internal Medicine, OB/Gyne, Emergency Services and OPD Clinics, 2 months in Harvard Medical School Emergency Medicine Course"
            ]
        },
        {
            id: 12, name: 'Dr. Khaled Amer', image: '/Doctors/Amer.jpeg',
            speciality: 'Cosmetic & Plastic Surgery', languages: ['Arabic', 'English'],
            "coreCompetencies": {
                "Body contouring": [],
                "Liposuction – Liposculpture": [],
                "Arm lift": [],
                "Arm liposuction": [],
                "Abdominoplasty (Tummy tuck)": [],
                "Lipoabdominoplasty": [],
                "Thigh lift": [],
                "Buttock augmentation": [],
                "Buttock fat grafting (Brazillian butt)": [],
                "Eyelid lift (Blepharoplasty)": [],
                "Eyebrow lifting": [],
                "Lip reconstruction or lifting": [],
                "Neck lipoaspiration & neck lift": [],
                "Otoplasty (Ear reshaping)": [],
                "Face lift": [],
                "Rhinoplasty": [],
                "Breast lift & breast augmentation": [],
                "Breast reduction": [],
                "Breast reconstruction": [],
                "Male breast excision (Gynecomastia)": []
            },
            nationality: 'Egypt',
            summary: "More than 15 years of professional experience"
            ,
            qualifications: [
                "Specialist Plastic Surgery",
                "Egyptian Board Certified"
            ]
        },
        {
            id: 13, name: 'Dr. Mohan Rangaswamy', image: '/Doctors/Rangaswamy.jpeg'
            , speciality: 'Cosmetic & Plastic Surgery', languages: ['Hindi', 'English', 'Urdu', 'Tamil', 'Malayalam', 'functional Arabic'],
            coreCompetencies: {
                "Facial Cosmetic Surgery": [
                    "Eyebrow lifts",
                    "Eyelid surgery (blepharoplasty)",
                    "Face & Neck lifts",
                    "Face & Neck contouring (Vaser/J Plasma)",
                    "Rhinoplasty (Nose Correction)",
                    "Face fat grafting",
                    "Face implants"
                ],
                "Body Surgeries": [
                    "Body contouring by advanced liposuction",
                    "VASER, Laser, Power liposuction",
                    "Body tightening by Renuvion plasma device (J Plasma)",
                    "Lipoabdominoplasty (No drains)",
                    "Buttock enhancement by fat graft (BBL)",
                    "Calf contouring and augmentation"
                ],
                "Breast Surgery": [
                    "Breast augmentation",
                    "Breast reduction with lift",
                    "Breast lift",
                ],
                "Reconstructive Surgery and Hand": [
                    "Burn related scars and problems",
                    "Scars and deformities after injury, accidents",
                    "Birth defects including cleft, giant nevus, ear deformity, hands, and others",
                    "Breast reconstruction after mastectomy",
                    "All HAND surgeries"
                ],
            },
            nationality: 'Indian',
            summary: "",
            qualifications: [
                "More than 30 years of professional experience.",
            ]
        },

        {
            id: 15, name: 'Dr. Jeehan Abdul Qadir', image: '/Doctors/Qadir.jpeg',
            speciality: 'Dermatology & Anti aging', languages: ['Arabic', 'English'],
            qualifications: [
                "Dr. Jeehan has been awarded with Emirates Business Woman Award & Leadership (2012) by H. H. Sheikh Ahmed Bin Saeed Al Maktoum.",
                "Award of Appreciation from Ministry of Health, Dubai, United Arab Emirates.",
                "Active public member in the Egyptian Society of Women’s Health (ESWH) a nonprofit, nongovernmental organization that promotes and advocates for women’s health in Egypt.",
                "Dr. Jeehan has been awarded with Emirates Women Award (2014), by H. H. Sheikh Ahmed Bin Saeed Al Maktoum.",
                "Dr. Jeehan has been awarded for her active contribution as public speaker in the campaign 'Your Health is our Passion' from the Ministry of Presidential Affairs, Abu Dhabi, United Arab Emirates (2014).",
                "AACSH has been awarded appreciation award from International Society of Hair Restoration Surgery (ISHRS), DHCC, Dubai, United Arab Emirates (March 2018).",
                "Awarded Most Influential Women in Middle East and acclaimed for professional responsibility, International Women’s Day, United Arab Emirates (March 2018."
            ],
            nationality: 'United Arab Emirates',
            summary: "Dr. Jeehan Abdul Qadir is an Emirati woman, married, and the mother of three children. She is the founder of “The American Academy of Cosmetic Surgery Hospital”, the first cosmetic hospital in Dubai HealthCare City and a well-known cosmetic hospital in Dubai. Dr. Qadir received her degree from Dubai Medical College in UAE and worked as a dermatologist in the Department of Health and Medical Services (DOHMS) in Dupal. She received further dermatological training in Egypt and completed postgraduate courses in cosmetic laser technologies at Northwestern Memorial Medical Centre in the U.S. She serves as executive chairperson of \"Peyora,\" one of the largest online retailers of aesthetically pleasing goods.",
            coreCompetencies: {

            },

        },
        {
            id: 16,
            qualifications: [
                "Specialist Dermatologist (MD)",
                "Head of Dermatology & Anti-Aging Department at American Academy of Cosmetic Surgery Hospital",
                "She is certified from the Turkish Board",
                "Turkish Board of Dermato-Venereology",
                "Member of Turkish Medical Association",
                "Member of Turkish Dermato-Venereology Society",
                "International Membership in the American Academy of Cosmetic Surgery",
                "Supervising the American Association aesthetic courses"
            ],
            name: 'Dr. Mine Burcu Hurbas', image: '/Doctors/Hurbas.jpeg', speciality: 'Dermatology & Anti aging', languages: ['Turkish', 'English'],
            coreCompetencies: {
                "Cosmetic Dermatology": [
                    'Face, neck, hands, and body include',
                    'Injectables: Mesotherapy for Anti Aging and Skin rejuvenation (vitamin, minerals, amino acids, hyaluronic acid, growth factor, collagen bio-stimulators, stem cell injections, dermapen, Platelet Rich Plasma Therapy, Exosomes), Botox, Nefertiti neck, microbotox face and neck, Fillers, Chemical Peels, Threads',
                    'Treatment for Excessive Sweating by botulinum toxin injection',
                    'Vascular laser Therapy, Laser Tattoo Removal, Plexr Plus eyelid thinning, fractionation, and microneedling radiofrequency',
                    'Hair treatments includes Growth factor and Stem cell, Exosome treatment, PRP Hair Treatment (Platelet Rich Plasma), Mesotherapy, and Revage Laser Therapy',
                    'Regenera Activa Hair Loss Treatment'
                ],
                "General Dermatology": [
                    'Examination and Treatment of Skin Conditions, Dermatological Diseases',
                    'Electrocauterization',
                    'Dermoscopy',
                    'Management of acute and chronic skin diseases of both pediatric and adult age groups.',
                    'Management of Psoriasis, Vitiligo, Eczema, Acne, Acne scars, Rosacea, Alopecia, skin infections and nail disorders'
                ]
            },
            nationality: 'Turkish',
            summary: "Dr. Burcu Hurbas has over 19 years of experience in all aspects of dermatology, venereology, and aesthetic medicine. She worked in Istanbul and Dubai at AACSH for more than 16 years as a specialist dermatologist with a high interest in cosmetic and aesthetic dermatology. She provides a wide range of services, incorporating modern techniques in cosmetics.",

        },
        {
            id: 17, name: 'Dr. Inam Faiq', image: '/Doctors/Faiq.jpeg',
            speciality: 'Dermatology & Anti aging', languages: ['Arabic', 'English'],
            "coreCompetencies": {
                "Clinical Dermatology & Skin Conditions": [],
                "Clinical Dermatology & Skin Conditions": [],
                "Botox & Fillers": [],
                "Mesotherapy & PRP": [],
                "Skin Rejuvenation & Glow": [],
                "Face Thread Lifting": [],
                "Hyperpigmentation Treatment": [],
                "Acne and Acne Scars Treatment": [],
                "Chemical Peel": [],
                "Laser Skin Rejuvenation Treatment": []
            },
            nationality: 'United Arab Emirates',
            summary: "Dr Inaam Faiq has over 20 years of experience in aesthetic medicine, with over 15 years of practice in Dubai. She is committed to offering personalized care to all her patients and discussing all the possible treatment options.After achieving her degree, she ensures to remain up-to-date with the latest developments in the field, always striving to combine her knowledge with modern technology to enable the best results.She believes there is nothing that can beat natural beauty and with the help of precision medicine, she strives to bring out the best in her patients.",
            qualifications: [
                "MBCHB Mustansiriyah University, Baghdad",
                "Certified by the American Academy of Anti aging Medicine",
                "Certified by the American board in Aesthetic Medicine"
            ]
        },

        {
            id: 21,
            name: 'Dr. Rita Nawar',
            image: '/Doctors/Nawar.jpeg',
            speciality: 'Endocrinology',
            languages: ['Arabic', 'French', 'English'],
            coreCompetencies: {
                "Diabetes Mellitus, prediabetes, and gestational diabetes": [],
                "Obesity, insulin resistance/hyperinsulinemia & metabolic diseases": [],
                "PCOS": [],
                "Thyroid diseases": [],
                "General endocrine diseases": []
            },
            nationality: 'Lebanese',
            spokenLanguages: ['Arabic', 'French', 'English'],
            summary: "After obtaining her medical degree in 1998, Dr. Rita Nawar completed her residency training in internal medicine at the American University of Beirut in Lebanon. Then she undertook a clinical and research fellowship in endocrinology and metabolism there. She pursued further training with a 2-year fellowship in diabetes endocrinology and metabolism at Case Western Reserve University in Cleveland, Ohio, USA. Dr. Rita Nawar was profoundly involved in medical obesity management, prediabetes, and diabetes as a Medical Director and Endocrinologist in Dubai, UAE, from 2007 until 2023. Since 2023, Dr. Nawar has joined the AACSH and continues her practice there. With over 16 years spent treating patients with obesity, diabetes, and metabolic diseases, Dr. Nawar has participated in authoring the Clinical Practice Recommendations for the Management of Obesity in the United Arab Emirates in 2018. She has also participated in several international and national studies about obesity, such as ACTIO-IO and ACTION-UAE. She has also participated in multiple educational activities about obesity on several in-person and virtual platforms. Dr. Nawar is SCOPE certified (World Obesity Federation) for obesity professional education and is also a member of several international societies, including The Obesity Society (TOS), AACE, The Endocrine Society, and ADA. Dr. Nawar has several research projects and publications in the field.",
            qualifications: [
                "MD (Lebanese university- Faculty of Medical Sciences)",
                "SCOPE certified (world obesity Federation)"
            ]
        },
        // { id: 22, name: 'Dr. Haddo Abdullah', image: '/Doctors/Abdullah.jpeg', speciality: 'ENT', languages: ['Arabic', 'English'] },
        {
            id: 27, name: 'Dr. Ahmed Almousa', image: '/Doctors/Almousa.jpg',
            speciality: 'Orthopedics Surgery', languages: ['Arabic', 'English', 'German', 'English'],

            coreCompetencies: {

            },
            nationality: "German",
            summary: "Dr. Ahmed Almousa is an Orthopedic surgeon in Dubai Healthcare City, Dubai and has an experience of 16 years in this field. Dr. Ahmed Almousa practices at American Academy Of Cosmetic Surgery Hospital (AACSH) FZ-LLC in Dubai Healthcare City.",
            "qualifications": [
                "Bachelor of Medicine and Surgery, MBBCh University of Al-Fateh – Faculty of Medicine, Tripoli, Libya",
                "German Approbation",
                "German Board of Orthopaedics and Trauma Surgery",
                "Certificate Foot Surgery (German Society for Foot and Ankle Surgery)",
                "Certificate for Manuell Therapy bei MWE Germany",
                "Physical Therapy und Balneology (European Institute for Physical Therapy und Balneology (EIPB))",
                "Sport Medicine (Damp Academy), Germany (ZTK 01 – 08)",
                "International Business Management Institute (IBMI) (Economics and International Business, Marketing and Communications, Essential Management Skills)"
            ]

        },
        // {
        //     id: 29, name: 'Dr. Nadia Buhannad', image: '/Doctors/Buhannad.jpeg', speciality: 'Psychology',
        //     languages: ['Arabic', 'English'],
        //     coreCompetencies: {
        //         "Acceptance Commitment Therapy (ACT) for Adults and for Adolescents 2023": [],
        //         "Accredited to use Emotional Competence Inventory (ECI)/ EQ for the assessment of emotional intelligence since 2000": [],
        //         "Qualified to use the Myers-Briggs Type Indicator (MBTI-Personality assessment) from USA since 2002": [],
        //         "Certified (Thought Field Therapy) TFT practitioner, London, 2003": [],
        //         "Cases of sexual harassment of children and homosexuality": [],
        //         "Parenting counseling": [],
        //         "Marriage counseling": []
        //     },
        //     nationality: "Emirati, UAE National",
        //     summary: "Dr. Nadia Buhannad has 24 years of experience in providing psychological, educational, and career counseling, mainly in the UAE. She has counseled over 3,000 hours. Some of the cases she follows include phobias and fears (social phobia, fear of flying, panic attacks, etc.), stress and anxiety (public anxiety, test anxiety, personal interviews, anxiety about intimacy at the beginning of marriage, etc.), behavioral problems to cope with (separation, divorce, death of a loved one, failure at work or study, etc.), personal development for individuals in general and for the employee (self-confidence, anxiety, dealing with others, emotional intelligence, etc.), and coaching and training employees to deal with co-workers (individual sessions or group training).",
        //     qualifications: [
        //         "Master of Science in Anesthesia, Critical Care, and Pain Management, Damascus University.",
        //         "Syrian board in anesthesia and reanimation",
        //     ]
        // },
        {
            id: 32,
            name: 'Dr. Hasan Mahfood',
            image: '/Doctors/Hassann.jpeg',
            speciality: 'Cardiologist',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Hypertension": [],
                "Heart failure and Cardiomyopathy": [],
                "Ischemic heart disease": [],
                "Arrhythmia": [],
                "Valvular heart disease": [],
                "Pericardial disease": [],
                "Preventive cardiology": [],
                "Echocardiography": [],
                "Holter": [],
                "Cardiopulmonary Stress test": []
            },
            nationality: 'Syrian',
            summary: "Dr. Hasan Mahfood is a Specialist cardiologist with 7 years’ experience in all aspects of cardiology, especially hypertension management, preventive cardiology, and heart failure.",
            qualifications: [
                "Master Degree in Cardiology from Tishreen University of Syria",
                "Syrian Board of Cardiovascular Medicine",
                "Membership in ESC (European Society of Cardiology)",
                "Membership in ECS (Emirates Cardiac Society)",
                "Membership in EACVI (European Association of Cardiovascular Imaging)",
                "Membership in EAPC (European Association of Preventive Cardiology)",
                "Membership in HFA (Heart Failure Association)"
            ]
        },
        {
            id: 31,
            name: 'Dr. Abdel Hamid Ibrahim',
            image: '/Doctors/Hamid1.png',
            speciality: 'General Practitioner',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "emergency Medicine": [],
                "surgery": [],
                "internal Medicine": [],
                "pediatrics": [],
                "clinicalAuditResearch": [],
            },

            nationality: 'Sudanese',
            summary: "Dr. Abdelhamid Abuzied, a general practitioner (GP) with around 6 years of experience in emergency medicine, medicine, pediatrics, surgery, and clinics. Former Clinical Auditor at Emirates Classification Society (TASNEEF), Abu Dhabi. International researcher, former research reviewer at PLM journal, New York, USA, and clinical research reviewer in medical education and curricular development, United Kingdom.",
            qualifications: [
                'Dubai Health Authority (DHA) license',
                'UAE Ministry of Health (MOH) license',
                'Clinical Reviewer at  journal of Health ,Springer Nature, United Kingdom(UK) UAE',
                'Sudan Medical Council Permanent Registration',
                '(Msc) )Master of Health Professions Education, SMSB',
            ]
        },
        {
            id: 32,
            name: 'Dr. Mohamed Abdallah Ali Babiker',
            image: '/Doctors/Babiker1.png',
            speciality: 'General Practitioner',
            languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Emergency Medicine": [],
                "ICU": []
            },
            nationality: 'Sudanese',
            spokenLanguages: ['Arabic', 'English'],
            summary: "Dr. Mohammed Abdallah is a General Practitioner and former emergency medicine registrar.",
            qualifications: [
                "MRCEM SBA (Membership of Royal College of Emergency Medicine UK - Part 2)",
                "MRCEM PRIMARY (Membership of Royal College of Emergency Medicine UK - Part 1)",
                "M.P.H 'Master of Public Health' (Ongoing)"
            ]
        },
        {
            id: 33, name: 'Dr. Kris Wasilewski', image: '/Doctors/kris.png',
            speciality: 'Consultant Vascular Surgery', languages: ['Russian', 'Polish', "English"],
            coreCompetencies: {
                "Diagnosis and Latest Treatment of Veins and Peripheral Arterial Diseases": [],
                "Latest Less Invasive Methods of Endovenous Ablation By Laser, Rf": [],
                "Venoseal And Foam Sclerotherapy Of Truncal Veins and Varicose Veins": [],
                "Mini Phlebectomy, Surgical Standard Procedures And Electro Procedures": [],
            },
            nationality: 'Poland',
            summary: "Dr. Kris Wasilewski MD, PhD has more than 35 years of professional experience in surgery. He has been trained in Poland, Germany, France and the US. In 1992 he began practicing in Dubai with special interest in vascular surgery. For the last 15 years his major concern is Phlebology- diagnosis and treatment of venous diseases. Dr. Kris has pioneered new sophisticated mini-invasive methods for the treatment of varicose veins in the region, using Laser, Radiofrequency, VenaSeal and ultrasound guided sclerotherapy. He has always introduced the latest and most advanced methods of vein treatments to his practice.",
            qualifications: [
                "MD, PHD- Poland",
                "European Board of Surgery",
                "Full Specialist Registration in the General Medical Council (UK)",
                "Member of American College of Phlebology and European Venous Forum"
            ]
        },
        // {
        //     id: 34, name: 'Dr. Martain Loonen', image: '/Doctors/martin1.png', speciality: 'Cosmetic & Plastic Surgery',
        //     languages: ['Dutch', 'English', 'French', 'German'],
        //     coreCompetencies: {
        //         "Liposuction": [],
        //         "Abdominoplasty": [],
        //         "Hair Restoration Surgery and Hair Loss Treatments": [],
        //         "Eyelid Surgery": [],
        //         "Dermatological Surgery": [],
        //         "Breast Augmentation": [],
        //         "Breast Lift and Breast Reduction Surgery": [],
        //         "Arm and Thigh Lift Surgery": [],
        //         "Reconstructive Surgery": [],
        //         "Hand Surgery": []
        //     },
        //     nationality: 'Dutch',
        //     summary: "Dr. Martain Pierre Jean Loonen is an experienced plastic surgeon. He is a consultant in plastic surgery with extensive experience and interest in plastic, reconstructive, cosmetic, hand, and wrist surgery. He has additional expertise in post-bariatric surgery, hair restoration surgery, and burn management. Dr. Loonen is a faculty member for the American Academy of Aesthetic Medicine, providing international courses in aesthetic and hair loss treatments. He is an invited healthcare expert consultant for the Gerson Lehrman Group in New York, specializing in the scientific evaluation and market implementation of pharmaceutical drugs and treatments for the aesthetic and wound care industries. He is a recognized fellow of the Collegium Chirurgicum Plasticum of the Board of Plastic Surgeons of Belgium and holds recognized membership in the Dutch Society of Plastic Surgeons. Dr. Loonen holds a doctoral degree in plastic surgery and has achieved his degree with outstanding honors as the youngest Ph.D. candidate to have attained that distinction at his alma mater, the University of Utrecht in the Netherlands, in collaboration with the University of Louisville, Kentucky. His published work was cited nearly 500 times in the international scientific literature. In addition to his surgical research endeavors, he is an editorial board member of fifteen international journals, including the American Journal of Biomedical Science and Research, the Journal of Advance Cancer Research, Cosmetology and Oro Facial Surgery, the Journal of Aesthetic & Reconstructive Surgery, and the International Journal of Bioscience and Bioengineering. He has extensive experience in medical education in anatomy,clinical surgery, and clinical practice for medical students, physiotherapists, and nurse staff.",
        //     qualifications: [
        //         "Belgian Board Certified Plastic Surgeon",
        //         "Ph.D. in Plastic Surgery (Netherlands)",
        //         "M.D. (Netherlands)"
        //     ]
        // },
        {
            id: 35, name: 'Dr. Ziad Nimer', image: '/Doctors/DrZiad1.png', speciality: 'General Practitioner', languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Plastic Surgery": [],
                "General Surgery": [],
                "Orthopedic Surgery": [],
                "Bariatric Surgery": []
            },
            nationality: "Jordanian",
            summary: "Dr. Ziad Ahmad Nimer graduated from Medical College in Baghdad, Iraq, in the year 2000. He completed his residency and training of 5 years in Jordanian private and public governmental hospitals in 2010 in general surgery. Dr. Nimer practiced as a general surgeon in Jordan until 2016 and later moved to Dubai, where he has been practicing as a general physician/surgeon to date.",
            qualifications: [
                "MD Iraq, 2000",
                "GP, Jordan 2010",
            ]

        },

        {
            id: 99, name: 'Dr. Ahmed Khazbak',
            image: '/Doctors/Khazbak.png',
            speciality: 'Consultant Plastic Surgery',
            languages: ['Arabic', 'English'],
            summary: "Dr. Ahmed Khazbak is an expert consultant plastic surgeon with 20 years of experience in the field. He is dedicated to helping his patients achieve their desired looks and enhancing their life and body form. Highly specialized in Breast surgeries, including breast augmentation, lift, and reduction. Besides Post Bariatric body contouring, including Liposuction, tummy tuck, and different contouring procedures.",
            nationality: 'Egypt',
            qualifications: [
                "Double Board-Certified Plastic Surgeon",
                "More Than 15 years of Professional Experience",
                "Ain Shams University, Post Graduate",
                "Universitat Autonoma de Barcelona, Postgraduate"
            ],
            "coreCompetencies": {
                "Rhinoplasty": [],
                "Mommy Makeover": [],
                "Weight Loss Procedures": [],
                "Brazilian Buttock Lift": [],
                "Gynecomastia Procedure": [],
                "Breast Augmentation": [],
                "Abdominoplasty": [],
                "Liposuction": [],
                "Post Weight Loss Body Contouring": []

            }

        },
        {
            id: 34, name: 'Dr. Eman Rabea Kalloub',
            image: '/Doctors/kalloub1.png',
            speciality: 'Specialist Pediatric Surgery',
            languages: ['Arabic', 'English', 'Swedish'],
            coreCompetencies: {
                "Full mouth Rehabilitation under general anesthesia": [],
                "Full teeth treatment under ( Nitrous-Oxide ) sedation": [],
                "Traumatic injury treatment ": [],
                "Pulp treatment for primary and immature permanent teeth with crowns ( and stainless steel crowns)": [],
                "Surgical extractions with space maintainer appliances.": []
            },
            nationality: 'Swedish',
            spokenLanguages: ['Arabic', 'English'],
            summary: "Dr.Eman Rabea Kalloub is a Swedish Doctor who has more than 15 years of experience working in pediatric dentistry in UAE and other countries. She graduated with distinction from Baghdad university/college of dentistry. Then completed her training courses in the pediatric dentistry field in Sweden. She is a member of the American and European Academies of Pediatric Dentistry. She was among the top 20 students during the course of her study. She ranked third among 321 students at her graduation. She was a senior student and a dental instructor in the Department of Oral and Maxillofacial Surgery. Then she finished her specialty in pediatric dentistry.",
            qualifications: [

            ]
        },
        {
            id: 35, name: 'Dr. Mohammadali Bayat Shahbazi',
            image: '/Doctors/Bayat.jpg',
            speciality: 'Specialist Plastic Surgery',
            languages: ['Persian', 'English'],
            summary: "Dr. Mohammad Ali Bayat Shahbazi was born in 1965. He received his MD degree from Tehran University of Medical Sciences in 1989 and completed a residency in general surgery in 1993 at the same university.After spending 7 years in the southern province of Bushehr as a surgeon, he entered Iran University of Medical Sciences to pursue a fellowship in cosmetic plastic and reconstructive surgery. In 2003, Dr. Bayat Shahbazi completed his fellowship as the top-ranked student of the class.Immediately after his fellowship, Dr. Bayat Shahbazi took the national board-certification exam and ranked 2nd to officially become a board-certified plastic surgeon. He later passed advanced courses in cosmetic laser surgery in England and Germany.",
            nationality: 'Iranian',
            qualifications: [
                "MD Tehran University of Medical Sciences",
                "Residency Tehran University of Medical Sciences",
                "Board Certified Plastic Surgeon"
            ],
            "coreCompetencies": {
                "Rhinoplasty": [],
                "Blepharoplasty (Eyelid Surgery)": [],
                "Otoplasty (Ear Surgery)": [],
                "Face, Forehead, and Neck Lift": [],
                "Orthognathic surgery": [],
                "Breast Lift and Breast Implants": [],
                "Abdominoplasty": [],
                "Liposuction/liposculpture": []
            }

        },
        {
            id: 98, name: 'Dr. Ziad Ibraheem',
            image: '/Doctors/Ibraheem.jpeg',
            speciality: 'Orthopedics Surgery',
            languages: ['Arabic', 'English'],
            summary: "Orthopedic specialist with 13 years of experience. Surgical and conservative treatment for acute and chronic musculoskeletal conditions.",
            nationality: 'Syrian',
            qualifications: [
                "Syrian Board In Orthopedic Surgery Ministry of Health, Syria",
                "More Than 13 years of Professional Experience",

            ],
            "coreCompetencies": {
                "Orthopedic Surgeon": [],


            }

        },
        {
            id: 35, name: 'Dr. Tarafa Nieseh', image: '/Doctors/tarafa.png', speciality: 'Specialist Plastic Surgery', languages: ['Arabic', 'English'],
            coreCompetencies: {
                "Botox": [],
                "Fillers": [],
                "Thread Face Lifting": [],
                "Liposuction & Fat Transfer": [],
                "Breast Surgery": []
            },
            nationality: "Syrian",
            summary: "Dr. Tarafa Nieseh is a Plastic Surgeon in Dubai Healthcare City, Dubai and has an experience of 16 years in this field. Dr. Tarafa Nieseh practices at American Academy Of Cosmetic Surgery Hospital (Aacsh) Fz-Llc in Dubai Healthcare City, Dubai. He completed Bachelor Of Medicine from Narimano Medical University in 1993.",
            qualifications: [
                "More Than 16 years of Professional Experience",
                "Bachelor of Medicine and Bachelor of Surgery - Narimano Medical University, Baku",
                "Plastic Surgery - Ministry of Health, Damascus"
            ]

        },
        {
            id: 36, name: 'Dr. Michael salivaras', image: '/Doctors/salivaras.png', speciality: 'Specialist Plastic Surgery', languages: ['Portuguese', 'English', 'Spanish', 'Greek', 'Arabic'],
            coreCompetencies: {
                "INVASIVE, MINIMALLY INVASIVE (Primary, secondary, tertiary):": [],
                "FACE": [
                    "Cervicofacial Lifting, Brow lifting, Endoscopic Forehead/Brow & Middle face lifting",
                    "Blepharoplasty",
                    "Otoplasty",
                    "Rhinoplasty, Septoplasty",
                    "Chin Augmentation",
                    "Buccal fat pad reduction",
                    "Facial Dimples creation",
                    "Facial/Neck fat grafting, liposuction",
                    "Lip Augmentation, Reduction, Lifting"
                ],
                "BREAST": [
                    "Breast Surgery (Augmentation, Mastopexy/Lifting, Reduction)",
                    "Gynecomastia (Male Breast Reduction)",
                    "Male pectoral augmentation",
                    "Ancillary breast reduction",
                    "Nipple areola correction (reduction, augmentation)"
                ],
                "BODY": [
                    "Brachioplasty (arm lifting, reduction, augmentation)",
                    "Thoracoplasty",
                    "Liposuction, Liposculpture (fat grafting), High definition liposculpture (tumescent, energy-assisted: plasma, laser, ultrasound, vibration)",
                    "Abdominoplasty (tummy tuck), Lipoabdominoplasty",
                    "Flankplasty",
                    "Gluteal/Buttocks Lifting, Augmentation, Reduction",
                    "Thigh lifting, reduction, augmentation",
                    "Calf augmentation, reduction",
                    "Upper Body Lifting",
                    "Lower Body Lifting",
                    "Post massive weight loss/bariatric plastic surgery"
                ],
                "NON INVASIVE PROCEDURES": [
                    "Neuromodulators, Fillers, Thread lifting",
                    "Dermabrasion, Peelings, Microneedling, Mesotherapy, Focused Ultrasound therapy/Ultherapy, MiraDry, Cellfina, Micropigmentation, Tattoo removal, Laser Hair removal, Velashape, LPG, Venus, Hydrafacial etc"
                ]
            },
            nationality: "Brazilian",
            summary: "Dr. Salivaras is a Brazilian and European Board-Certified Aesthetic and Reconstructive Plastic Surgeon and is considered one of the foremost international experts in Aesthetic Plastic Surgery. He is a member of the Brazilian, European, Emirates & International Society of Plastic Surgery, the American & International Society of Aesthetic Plastic Surgery, the Alumni Association of Professor Ivo Pitanguy and the Medical Society of the United Kingdom. Dr. Salivaras believes in a comprehensive approach to every patient.  He recognizes that each person requires an individualized treatment plan.  He encourages all patients considering image enhancement procedures to do their homework, ask questions and always insist on the very best.  “It is this unique combination of training, skill, knowledge, and compassion that makes him among the most sought after plastic surgeons” patients testify.",
            qualifications: [
                "Medical degree, National University of Athens",
                "Post Graduate Certificate, Pontifical Catholic University and Carlos Chagas Institute of PostGraduate Medical Studies in Rio de Janeiro, Brazil",
                "Specialist Title of Aesthetic & Reconstructive Plastic Surgeon in 2007 at the Institute Ivo Pitanguy in Rio de Janeiro, Brazil",
                "Sub-specialty training in Sao Paulo, Brazil and at the Southwestern Medical Centre at University of Texas in Dallas"
            ]

        },
        {
            id: 40, name: 'Dr. Andumbar Dashrath Borgaonkar', image: '/Doctors/Borgaonkar.jpg', speciality: 'Specialist Plastic Surgery', languages: ['Hindi', 'English'],
            coreCompetencies: {
                "FUE hair Transplant": [],
                "Reconstructive Surgeries": [],
                "Botox and Fillers": [],

            },
            nationality: "Indian",
            summary: "Dynamic plastic surgeon, looking forward to working in a reputed organization, which provides me practical exposure for growth by leveraging my knowledge and skills. Performed more than 1000 FUE Hair Transplants with successful results Also operated multiple cases of Gynaecomastia, Liposuction, Tummy Tuck, Breast implant,Breast reduction, Botox, fillers and various other cosmetic procedures. Operated cases of Congenital malformations, reconstruction surgeries , Facial fractures, A-V fistula (forDialysis).",
            qualifications: [
                "M.B.B.S.: –May 2001 –Jan 2006 - Internship:- Feb2006 - Feb 2007",
                "M.S.Gen.Surgery:- May 2008 - April 2011.M.M.College,Mumbai.",
                "M.Ch.PlasticSurgery:- Aug.2012- July 2015 B.J.MedicalCollege,Pune",
                "B.J.Medical College & Sasson General Hospital, Pune Grant Govt. Medical College & Sir J.J. Group of Hospitals, Mumbai."
            ]

        },
        {
            id: 40, name: 'Dr Mojtaba Malekidelarestaghi', image: '/Doctors/ENT.png', speciality: 'Specialist - Otolaryngologist / ENT', languages: ['Persian', 'English'],
            coreCompetencies: {
                "Rhinoplasty": [
                    "Primary Rhinoplasty",
                    "Revision Rhinoplasty"
                ],
                "Facial Plastic Surgery": [
                    "Endoscopic Forehead Lift",
                    "Face Lift",
                    "Blepharoplasty",
                    "Facial Fat Injection",
                    "Facial Contouring",
                    "Chin Augmentation",
                    "Cheek Augmentation",
                    "Lip Lift",
                    "Otoplasty",
                    "Botulinum Toxin Injection",
                    "Filler Injection"
                ],
                "Facial Reconstructive Surgeries": [
                    "Facial Rehabilitation Surgeries"
                ],
                "Head and Neck Surgeries": [
                    "Thyroidectomy",
                    "Parotidectomy",
                    "Neck Dissection",
                    "Laryngectomy",
                    "Transoral Laryngeal Surgeries",
                    "Submandibular Gland Surgeries",
                    "Oral Cavity Cancer Surgery"
                ],
                "Rhinology Surgeries": [
                    "Endoscopic Sinus Surgeries",
                    "Paranasal Sinus Cancer Surgeries",
                    "Septoplasty",
                    "Turbinoplasty",
                  
                ],
                "Ear Surgeries": [
                    "Endoscopic Tympanoplasty",
                    "Tympanoplasty",
                    "Mastoidectomy",
                    "Stapedotomy",
                  
                ],

            },
            nationality: "Iranian",
            summary: "Board certified of Otorhinolaryngology – Head and neck surgery. Member of Academic Board of Iran University of Medical Sciences. trained great numbers of Iranian otolaryngologists. Qualified in Rhinoplasty, facial plastic surgeries and Head and neck surgeries.",
            qualifications: [
                "Primary Medical Degree, Tehran University of Medical Sciences, Iran",
                "Special degree in Otolaryngology, Iran University of Medical Sciences, Tehran, Iran",
                "Permanent Medical license",
                "Otolaryngology Board Certification",
                "MD License Certification"
            ]

        },
        {
            id: 40, name: 'Dr Naser Mozafari', image: '/Doctors/Naser.png', speciality: 'Specialist Plastic Surgery', languages: ['Persian', 'English'],
            coreCompetencies: {
                "Rhinoplasty": [],
                "Mommy Makeover": [],
                "Weight Loss Procedures": [],
                "Brazilian Buttock Lift": [],
                "Gynecomastia Procedure": [],
                "Breast Augmentation": [],
                "Abdominoplasty": [],
                "Liposuction": [],
                "Post Weight Loss Body Contouring": []


            },
            nationality: "Iranian",
            summary: "Dr. Naser Mozafari has a prestigious background as a plastic surgeon at Medical Universities in Iran, and he holds a professorship degree in plastic surgery. He is very well known in the region for his expertise and experience at Iranian Hospital. Dr Mozafari also served as Director General of Iranian Hospital, Dubai",
            qualifications: [
                "More than 20 years of professional experience",
            ]

        },





    ];


    const doc = doctors.find(doc => doc.name.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '') == doctor);

    const [showAppointment, setShowAppointment] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    function DisplayPopUp() {
        console.log("test")
        setShowSuccessMessage(true)
    }
    function closePopUp() {
        setShowSuccessMessage(false)
    }

    useEffect(() => {
        const myFunction = () => {

            console.log(doc);
            // Your logic here
        };

        myFunction();
    }, [props]);
    return (
        <div style={{ marginTop: "100px" }}>
            <Appointment show={showAppointment} handleClose={handleCloseAppointment} showPopUp={DisplayPopUp} />
            <SuccessModal show={showSuccessMessage} handleClose={closePopUp} />

            <section id="doctors" className="doctors" >
                <div className="container" style={{ backgroundColor: "#69426f", borderRadius: '15px', minWidth: "85%" }}>
                    <div className='row'>
                        <div class="member d-flex align-items-start">
                            <div class="pic"><img src={doc.image} class="img-fluid" alt="" /></div>
                            <div class="member-info">
                                <h4 style={{ color: "white" }}>{doc.name}</h4>
                                <span style={{ color: "white" }}>{doc.speciality}</span>
                                <p style={{ color: "white" }}><b>Nationality:</b> {doc.nationality}</p>
                                <p style={{ color: "white" }}><b>Languages Spoken:</b> </p>

                                <ul style={{ marginTop: "10px" }}>
                                    {doc.languages && doc.languages.map(
                                        (l, index) => (
                                            // <p className="description" key={index}>{q}</p>
                                            <li key={index} style={{ color: "white" }}>{l}</li>
                                        )
                                    )}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="about" style={{ marginTop: "-100px", width: "85%", marginLeft: "auto", marginRight: "auto" }}>
                <div className="container-fluid">
                    <div className="row">

                        <div className="icon-box">
                            <div className="icon" style={{ marginTop: "-20px" }}><i className="bx bx-fingerprint"></i></div>
                            <h2 className="title"><a style={{ textDecoration: "none", color: "#69426f", fontSize: "19px" }} >Profile Summary</a></h2>
                            <p className="description" style={{ fontSize: "16px" }}>{doc.summary}</p>
                        </div>

                        <div className="icon-box">
                            <div className="icon" style={{ marginTop: "-20px" }}><i className="bx bx-briefcase"></i></div>
                            <h2 className="title" style={{ color: "#69426f", fontSize: "19px" }}>Core Competencies</h2>
                            {doc.coreCompetencies && (
                                <div>

                                    {Object.entries(doc.coreCompetencies).map(([competency, items]) => (
                                        items && Array.isArray(items) && ( // Check if items is an array
                                            <div key={competency}>
                                                {/* <ul>
                                                    <li  key={competency} style={{ marginLeft: "80px" }}> */}
                                                <h3 style={{ marginLeft: "100px", fontSize: "17px", marginTop: "12px", fontWeight: "600" }}>{competency}</h3>
                                                {/* </li>
                                                </ul> */}
                                                {/* <ul> */}
                                                {items.map((item, index) => (
                                                    <ul>
                                                        <li key={index} style={{ marginLeft: "120px", lineHeight: "18px" }}>{item}</li>
                                                    </ul>
                                                    // <p key={index} style={{marginLeft: "100px"}}>{item}</p>
                                                ))}
                                                {/* </ul> */}
                                            </div>
                                        )
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="icon-box">
                            <div className="icon" style={{ marginTop: "-20px" }}><i className="bx bx-badge"></i></div>
                            <h2 className="title"><a style={{ textDecoration: "none", color: "#69426f", fontSize: "19px" }}>Qualifications</a></h2>
                            <ul>
                                {doc.qualifications && doc.qualifications.map(
                                    (q, index) => (
                                        // <p className="description" key={index}>{q}</p>
                                        <li key={index} style={{ marginLeft: "80px" }}>{q}</li>
                                    )
                                )}
                            </ul>
                        </div>


                    </div>

                </div>
                <div style={{ marginTop: "50px", alignItems: "center", display: "flex", }}>
                    <a className="appointment-btn" onClick={handleShowAppointment} style={{ textDecoration: "none", marginRight: "auto", marginLeft: "auto" }} href="#appointment">Book Now</a>

                </div>

            </section>

        </div>
    )
}
export default DoctorProfile