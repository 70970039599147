import '../../index.css';
import React, { useState } from 'react';


function Counter() {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const iconStyle = (index) => ({
        color: hoveredIndex === index ? '#a880ad' : 'white', // Change to desired hover color
        transition: 'background-color 0.3s ease',
        backgroundColor: "white",
    });

    return (
        <section id="counts" className="counts" style={{ backgroundColor: "#a880ad" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6" onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave}>
                        <div className="count-box" style={{borderRadius: "10px", backgroundColor: "white"}}>
                            <i className="fas fa-user-md" style={iconStyle(0)}></i>
                            <h2 >29+</h2>
                            <p>Doctors</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-5 mt-md-0" onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave}>
                        <div className="count-box" style={{borderRadius: "10px", backgroundColor: "white"}}>
                            <i className="far fa-hospital" style={iconStyle(1)}></i>
                            <h2 >10+</h2>
                            <p>Specialities</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-5 mt-lg-0" onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave}>
                        <div className="count-box" style={{borderRadius: "10px", backgroundColor: "white"}}>
                            <i className="fas fa-flask" style={iconStyle(2)}></i>
                            <h2 >15000+</h2>
                            <p>Patients</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-5 mt-lg-0" onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={handleMouseLeave}>
                        <div className="count-box" style={{borderRadius: "10px", backgroundColor: "white"}}>
                            <i className="fas fa-award" style={iconStyle(3)}></i>
                            <h2 >98%</h2>
                            <p>Satisfaction</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Counter