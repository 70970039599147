import React, { useEffect } from 'react';


function DownloadPdf() {
    useEffect(() => {
        // Function to handle the automatic download and close the window
        const handleAutoDownload = () => {
            // Replace 'pdf/brochure.pdf' with the path to your PDF file
            const pdfUrl = process.env.PUBLIC_URL + '/pdf/brochure.pdf';

            // Create an anchor element
            const anchor = document.createElement('a');
            anchor.href = pdfUrl;
            anchor.download = 'brochure.pdf'; // Set the file name for download
            anchor.click(); // Trigger the click event to initiate download

            // Close the window after a short delay (e.g., 2 seconds)
            setTimeout(() => {
                window.location.href = '/';
            }, 60000); // Adjust the delay as needed
        };

        // Call the function to trigger automatic download and window close
        handleAutoDownload();
    }, []);

    return (
        <div>
            <p>PDF will be downloaded automatically.</p>
        </div>
    );

}
export default DownloadPdf