import React from 'react';

const JsonSchema = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Hospital",
    "image": [
      "https://www.aacsh.com/logo.png"
    ],
    "name": "American Academy of Cosmetic Surgery Hospital",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Building 73 Umm Hurair 2",
      "addressLocality": "Dubai Healthcare City",
      "addressRegion": "DXB",
      "postalCode": "00000",
      "addressCountry": "UAE"
    },
    "email": "info@aacsh.com",
    "telePhone": " +971 442 37600",
    "url": "https://www.aacsh.com/",
    "paymentAccepted": ["cash", "credit card", "invoice"],
    "openingHours": "24/7",
    "openingHoursSpecification": [{
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ]
    }]
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
    />
  );
};

export default JsonSchema;
