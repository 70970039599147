import '../../index.css';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';



function WhyUs() {

  const [isClicked1, setIsClicked1] = useState(false);

  const handleButtonClick = () => {
    setIsClicked1(!isClicked1);
  };

  const [isClicked2, setIsClicked2] = useState(false);

  const handleButtonClick2 = () => {
    setIsClicked2(!isClicked2);
  };

  const [isClicked3, setIsClicked3] = useState(false);

  const handleButtonClick3 = () => {
    setIsClicked3(!isClicked3);
  };

  const [isClickedMain, setIsClickedMain] = useState(false);

  const handleButtonClickMain = () => {
    setIsClickedMain(!isClickedMain);
  };
  return (
    <section id="why-us" className="why-us">
      <div className="container">
        <div className="row">
          <div style={{
            backgroundColor: "#69426f",
            ...(isClickedMain ? { height: '600px' } : {})
          }} className="col-lg-4 d-flex align-items-stretch" >
            <div style={{ backgroundColor: "#69426f" }} className="content">
              <h1 style={{ color: 'white', fontWeight: "700", fontSize: "34px" }}>Why Choose AACSH?</h1>
        
              <p>
                Choosing the American Academy of Cosmetic Surgery Hospital is a decision you'll never regret. As the first hospital in the region to offer comprehensive medical and cosmetic services, we are a state-of-the-art medical facility located in Dubai Healthcare City. Our team of expert doctors specialize in a wide array of aesthetic plastic surgery services, including both cosmetic and reconstructive procedures. Ensuring patient satisfaction is our ultimate goal, and we strive to provide an error-free service. We are also committed to education, professional development, and advocacy to advance the global discipline of cosmetic surgery and aesthetic medicine. To ensure the highest standards of compliance and enforcement of clinical protocols, AACSH operates independently. Trust us with your transformation, and we promise to unlock your beauty potential.
              </p>
              <div className="text-center">


              </div>
            </div>
          </div>

          <div className="col-lg-8 d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-xl-4 d-flex align-items-stretch" style={isClicked1 ? { height: '550px' } : {}}>
                  <div className="icon-box mt-4 mt-xl-0">

                    <i style={{ color: "#69426f", marginTop: "-25px" }} className="bx bx-receipt"></i>
                    <h3 style={{ color: "#69426f" , marginTop: "-25px", fontSize: "20px", fontWeight: "700"}} >Enhancing Natural Beauty with Advanced Plastic Surgery Procedures</h3>
                
                    <p className="words">We take pride in offering a broad spectrum of leading-edge plastic surgery procedures. Our mission is to enhance your natural beauty and boost your self-confidence. From facelifts, eyelid surgery, to breast augmentations and tummy tucks, our experienced surgeons customize solutions to meet your unique needs, ensuring natural-looking results.</p>
                    
                  </div>
                  
                </div>
                <div className="col-xl-4 d-flex align-items-stretch" style={isClicked2 ? { height: '500px' } : {}}>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i style={{ color: "#69426f", marginTop: "-25px" }} className="bx bx-cube-alt"></i>
                    <h3 style={{ color: "#69426f", marginTop: "-25px", fontSize: "20px", fontWeight: "700" }}>Renowned Surgical Team</h3>
               
                    <p className="words" style={{marginTop: "75px"}}><span>Our Plastic Surgery Department is home to a team of well-qualified, highly experienced surgeons who are regarded as some of the best in the industry.</span><span> With a focus on both aesthetic and reconstructive surgeries, our practitioners offer state-of-the-art procedures and exceptional patient care.</span></p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch" style={isClicked3 ? { height: '600px' } : {}}>
                  <div className="icon-box mt-4 mt-xl-0">
                    <i style={{ color: "#69426f", marginTop: "-25px" }} className="bx bx-images"></i>
                    <h3 style={{ color: "#69426f", marginTop: "-25px", fontSize: "20px", fontWeight: "700" }}>Leading the Way in Aesthetic Excellence</h3>
                  
                    <p style={{marginTop: "75px"}}>Setting the standard for cosmetic surgery in Dubai, AACSH leads the way in aesthetic excellence. The hospital's team of expert surgeons and state-of-the-art facilities ensure top-tier service and patient satisfaction. Accredited by the Joint Commission International, the hospital's dedication to health education and error-free service has made it a trusted name in healthcare, earning excellent customer feedback for its reliable, loyal, and innovative approach.</p>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>



      </div>
    </section>
  )
}

export default WhyUs