import Hero from "../Hero/Hero"
import WhyUs from "../WhyUs/WhyUs";
import '../../index.css';
import Specialities from "../Specialities/Specialities";
import FeatureList from "../FeatureList/FeatureList";
import DoctorsList from "../DoctorsList/DoctorsList";
import Gallery from "../Gallery/Gallery";
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import React, { useState, useEffect } from 'react';
import Articles from "../Articles/Articles";
import Counter from "../Counter/Counter";
import Testimonials from "../Testimonials/Testimonials";
import { Helmet } from 'react-helmet';

function Home({ onComponentChange, changeService }) {

    useEffect(() => {
        // Load Google Analytics script
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H0L89HEMKP';
        script.async = true;
        document.head.appendChild(script);

        // Initialize Google Analytics
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-H0L89HEMKP');
        };

        // Cleanup script tag on component unmount
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    return (
        <div>
            <Helmet>
                <title>Best Plastic Surgery and Hair Transplant Hospital In Dubai | AACSH

                </title>
                <meta name="description" content="AACSH is the leading plastic surgery, cosmetic reconstructive treatment and hair transplant hospital in Dubai. Get a complete range of surgical and non-surgical treatments with AACSH." />
                {/* Add more meta tags if needed */}
            </Helmet>
            <Hero />
            <main id="main">
                <WhyUs />
                <Counter />
                <FeatureList onComponentChange={onComponentChange} changeService={changeService}/>
                <Articles onComponentChange={onComponentChange} />
                <Gallery />
                <Testimonials />
            </main>
        </div>
    )
}

export default Home