import '../../index.css';
import emailjs from 'emailjs-com';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';


function Contact() {
  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H0L89HEMKP';
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-H0L89HEMKP');
    };

    // Cleanup script tag on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const goToGoogleMaps = () => {
    // Replace the latitude and longitude values with the desired location
    const latitude = 25.2326705;
    const longitude = 55.3195453;

    // Open Google Maps in a new tab with the specified location
    window.open(`https://maps.app.goo.gl/e73ksmiRFsDr64Aw5`);
  };

  function sendEmail(e) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    //This is important, i'm not sure why, but the email won't send without it

    emailjs.sendForm('service_fwgrqew', 'template_p1l1t0j', e.target, 'tKbfD7Qc7HH_s5Z5R')
      .then((result) => {
        window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
        console.log(error.text);
      });

  }
  return (
    <div>
      <Helmet>
        <title>Book Your Appointment Now | AACSH Dubai</title>
        <meta name="description" content="Book your free consultation for plastic surgery, cosmetic surgery, & skincare treatments with our elite professionals at AACSH." />
        {/* Add more meta tags if needed */}
      </Helmet>
      <section id="contact" className="contact" style={{ marginTop: "100px" }}>
        <div className="container">

          <div className="section-title" >
            <h2 style={{ color: "#2c4964" }}>Contact Us</h2>
          </div>
        </div>

        <div className="container" style={{ marginBottom: "50px" }}>
          <div className="row mt-5">

            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p><a href="#" style={{ textDecoration: "none" }} onClick={goToGoogleMaps}>Building 73 - Umm Hurair 2 - Dubai Healthcare City - Dubai - United Arab Emirates</a></p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p><a href="mailto:contact@example.com" style={{ textDecoration: "none" }}>info@aacsh.com</a></p>
                </div>

                <div className="phone" >
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p><a style={{ textDecoration: "none" }} href="tel:6177787312">+971 442 37600 +971 55 3562018</a></p>
                </div>

              </div>

            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">

              <form onSubmit={sendEmail} action="forms/contact.php" method="post" role="form" className="php-email-form">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                  </div>
                  <div className="col-md-4 form-group mt-3 mt-md-0">
                    <input type="number" className="form-control" name="phone" id="phone" placeholder="Your Number" required />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input type="hidden" className="form-control" name="sendTarget" value="Mohammed.marwan@aacsh.com" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required />
                  <div className="validate"></div>
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <input type="hidden" className="form-control" name="sendTargetTwo" value="Leads@aacsh.com" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required />
                  <div className="validate"></div>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
              </form>

            </div>

          </div>

        </div>

        <div>
          <iframe style={{ border: '0', width: '100%', height: '350px' }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14436.505220360608!2d55.3195453!3d25.2326705!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d3426a43219%3A0x182acbe5d859f0b!2sAmerican%20Academy%20of%20Cosmetic%20Surgery%20Hospital!5e0!3m2!1sen!2sae!4v1700988200023!5m2!1sen!2sae" frameBorder="0" allowFullScreen title="Google Map"></iframe>
        </div>


      </section>
    </div>
  )
}
export default Contact