// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .carousel-control-prev-icon {
    background-color: #69426f; 
  }
  
  .carousel-control-next-icon {
    background-color: #69426f; 
  }

.carousel-container .carousel-control-prev,
.carousel-container .carousel-control-next {
  display: block;
} */`, "",{"version":3,"sources":["webpack://./src/components/SpecialitiesPage/SpecialitiesPage.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;GAWG","sourcesContent":["/* .carousel-control-prev-icon {\r\n    background-color: #69426f; \r\n  }\r\n  \r\n  .carousel-control-next-icon {\r\n    background-color: #69426f; \r\n  }\r\n\r\n.carousel-container .carousel-control-prev,\r\n.carousel-container .carousel-control-next {\r\n  display: block;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
