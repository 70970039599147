import '../../index.css';
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Testimonials.css';
function Testimonials() {

  const testimonials = [
    {
      name: 'Nibras Alneaimy',
      role: 'Patient',
      image: '/Doctors/user.png',
      quote: 'I had a very good experience with Dr Burcu. All the stuff are kind and helpful, especially the Tunisian girls, Soulaima and Fatma.'
    },
    {
      name: 'Noran Ahmed',
      role: 'Patient',
      image: '/Doctors/user.png',
      quote: 'It was such a good experience. The whole staff were very friendly and will help with everything you need, and the people working at the reception were amazingly nice and very welcoming. I would definitely recommend this hospital to anyone wanting to do any cosmetic procedure.'
    },
    {
      name: 'Hbabache Mouna',
      role: 'Patient',
      image: '/Doctors/user.png',
      quote: 'A very nice experience and very classy treatment. May God grant you wellness.'
    },
    {
      name: 'Randa',
      role: 'Patient',
      image: '/Doctors/user.png',
      quote: 'A great hospital with very professional doctors and staff. Dr. Inaam is a great talented doctor that I am happy I dealt with. She is far from being commercial and does what is best for each case. Thanks and please keep the good work up.'
    },
    {
      name: 'Ghita El Idrissi',
      role: 'Patient',
      image: '/Doctors/user.png',
      quote: 'I found all reception and nurses staff all very nice friendly and wonderful and even the food was very good and special with Tawhida.'
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section id="testimonials" className="testimonials">
      <Container>
        <Row style={{ marginBottom: "50px" }}>
          <Col md={8} className="mx-auto">
            <Carousel activeIndex={currentIndex} onSelect={handleSlide} interval={null}>
              {testimonials.map((testimonial, index) => (
                <Carousel.Item key={index}>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="testimonial-item">
                        {/* <img src={testimonial.image} className="testimonial-img" alt="" /> */}
                        <h3>{testimonial.name}</h3>
                        <h4>{testimonial.role}</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                          {testimonial.quote}
                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonials