// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container .slider {
    animation: slidein 30s linear infinite;
    white-space: nowrap;
  }
  
  .container .slider .logos {
    width: 100%;
    display: inline-block;
    margin: 0px 0;
  }
  
  .container .slider .logos .fab {
    width: calc(100% / 5);
    animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  }
  
  @keyframes slidein {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Insurances/Insurances.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,qBAAqB;IACrB,wEAAwE;EAC1E;;EAEA;IACE;MACE,+BAA+B;IACjC;IACA;MACE,mCAAmC;IACrC;EACF;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".container .slider {\r\n    animation: slidein 30s linear infinite;\r\n    white-space: nowrap;\r\n  }\r\n  \r\n  .container .slider .logos {\r\n    width: 100%;\r\n    display: inline-block;\r\n    margin: 0px 0;\r\n  }\r\n  \r\n  .container .slider .logos .fab {\r\n    width: calc(100% / 5);\r\n    animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;\r\n  }\r\n  \r\n  @keyframes slidein {\r\n    from {\r\n      transform: translate3d(0, 0, 0);\r\n    }\r\n    to {\r\n      transform: translate3d(-100%, 0, 0);\r\n    }\r\n  }\r\n  \r\n  @keyframes fade-in {\r\n    0% {\r\n      opacity: 0;\r\n    }\r\n    100% {\r\n      opacity: 1;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
