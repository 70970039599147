import '../../index.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Appointment from '../Appointment/Appointment';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import SuccessModal from '../SuccessModal/SuccessModal';
const logo = process.env.PUBLIC_URL + '/logo.png';
function NavBar({ onComponentChange }) {

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [showAppointment, setShowAppointment] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);


    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);

    const [activeNavItem, setActiveNavItem] = useState('home');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleNavItemClick = (item) => {
        setActiveNavItem(item);
        onComponentChange(item, '');
        if (show == true) {
            handleClose()
        }
    };


    function DisplayPopUp() {
        console.log("test")
        setShowSuccessMessage(true)
    }

    function closePopUp() {
        setShowSuccessMessage(false)
    }




    return (
        <div >

            <SuccessModal show={showSuccessMessage} handleClose={closePopUp} />
            <Appointment show={showAppointment} handleClose={handleCloseAppointment} showPopUp={DisplayPopUp} />
            <div id="topbar" className="d-flex align-items-center fixed-top">
                <div className="container d-flex justify-content-between">
                    <div className="contact-info d-flex align-items-center">
                        <i style={{ color: "#69426f" }} className="bi bi-envelope"></i> <a href="mailto:info@aacsh.com">info@aacsh.com</a>
                        <i style={{ color: "#69426f" }} className="bi bi-phone"></i> <a href="tel:+97144237600">+971 442 37600</a>

                    </div>
                    <div className="d-none d-lg-flex social-links align-items-center">
                        <a href="https://www.instagram.com/aacshospital/" className="instagram"><i style={{ color: "#69426f" }} className="bi bi-instagram"></i></a>
                        <a href="https://www.tiktok.com/@aacsh.hospital" className="linkedin"><i style={{ color: "#69426f" }} className="bi bi-tiktok"></i></a>
                        <a href="https://www.facebook.com/AACSHospital/" className="facebook"><i style={{ color: "#69426f" }} className="bi bi-facebook"></i></a>
                        <a href="https://www.linkedin.com/company/american-academy-of-cosmetic-surgery-hospital" className="linkedin"><i style={{ color: "#69426f" }} className="bi bi-linkedin"></i></a>
                        <a href="https://twitter.com/AACSHospital" className="twitter"><i style={{ color: "#69426f" }} className="bi bi-twitter-x"></i></a>

                    </div>
                </div>
            </div>

            {/* ======= Header ======= */}
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">

                    <Link to="/" onClick={() => handleNavItemClick('home')} className="logo me-auto"><img className="img-fluid" src={logo} style={{ width: "250px", height: "100%", objectFit: "cover" }} /></Link>
                    {/* Uncomment below if you prefer to use an image logo */}
                    {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}

                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><Link style={{ color: activeNavItem === 'home' ? "#69426f" : "" }}
                                to="/"
                                className={`nav-link scrollto ${activeNavItem === 'home' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('home')}>Home</Link></li>
                            <li><Link to="/about-us" style={{ color: activeNavItem === 'about' ? "#69426f" : "" }} className={`nav-link scrollto ${activeNavItem === 'about' ? 'active' : ''}`} onClick={() => handleNavItemClick('about')}>About</Link></li>
                            {/* <li><Link style={{ color: activeNavItem === 'specialities' ? "#69426f" : "" }}
                                to="/specialities"
                                className={`nav-link scrollto ${activeNavItem === 'specialities' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('specialities')}>Specialities</Link></li> */}
                            <li><Link style={{ color: activeNavItem === 'offers' ? "#69426f" : "" }}
                                to="/our-specialties"
                                className={`nav-link scrollto ${activeNavItem === 'offers' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('offers')}>Specialities</Link></li>
                            <li><Link style={{ color: activeNavItem === 'doctors' ? "#69426f" : "" }}

                                to="/doctors"
                                className={`nav-link scrollto ${activeNavItem === 'doctors' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('doctors')}>Doctors</Link></li>

                            <li><Link style={{ color: activeNavItem === 'contact' ? "#69426f" : "" }}
                                to="/contact-us"
                                className={`nav-link scrollto ${activeNavItem === 'contact' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('contact')}>Contact</Link></li>
                            <li><Link style={{ color: activeNavItem === 'education' ? "#69426f" : "" }}
                                to="/education"
                                className={`nav-link scrollto ${activeNavItem === 'education' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('education')}>Education</Link></li>
                            <li><Link style={{ color: activeNavItem === 'AllArticles' ? "#69426f" : "" }}
                                to="/blogs"
                                className={`nav-link scrollto ${activeNavItem === 'AllArticles' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('AllArticles')}>Articles</Link></li>

                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleShow}></i>
                    </nav>{/* .navbar */}

                    <a onClick={handleShowAppointment} style={{ textDecoration: "none" }} href="#appointment" className="appointment-btn scrollto"><span className="d-none d-md-inline" >Make an</span> Appointment</a>

                </div>
            </header>{/* End Header */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup >
                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'home' ? "#69426f" : "" }}
                                to="/"
                                className={`nav-link scrollto ${activeNavItem === 'home' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('home')}>Home</Link>
                        </ListGroup.Item>
                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'about' ? "#69426f" : "" }} to="/about-us" className={`nav-link scrollto ${activeNavItem === 'about' ? 'active' : ''}`} href="#about" onClick={() => handleNavItemClick('about')}>About</Link>
                        </ListGroup.Item>
                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'doctors' ? "#69426f" : "" }}
                                to="/doctors"
                                className={`nav-link scrollto ${activeNavItem === 'doctors' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('doctors')}>Doctors</Link>
                        </ListGroup.Item>
                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'contact' ? "#69426f" : "" }}
                                to="/contact-us"
                                className={`nav-link scrollto ${activeNavItem === 'contact' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('contact')}>Contact</Link>
                        </ListGroup.Item>

                        {/* <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'specialities' ? "#69426f" : "" }}
                                to="/specialities"
                                className={`nav-link scrollto ${activeNavItem === 'specialities' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('specialities')}>Specialities</Link>
                        </ListGroup.Item> */}
                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'offers' ? "#69426f" : "" }}
                                to="/our-specialties"
                                className={`nav-link scrollto ${activeNavItem === 'offers' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('offers')}>Specialities</Link>
                        </ListGroup.Item>
                        {/* <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'careers' ? "#69426f" : "" }}
                                to="/careers"
                                className={`nav-link scrollto ${activeNavItem === 'careers' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('careers')}>Careers</Link>
                        </ListGroup.Item> */}
                        {/* <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'privacy' ? "#69426f" : "" }}
                                to="/privacy"
                                className={`nav-link scrollto ${activeNavItem === 'privacy' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('privacy')}>Privacy Policy</Link>
                        </ListGroup.Item> */}
                        {/* <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'insurances' ? "#69426f" : "" }}
                                to="/insurances"
                                className={`nav-link scrollto ${activeNavItem === 'insurances' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('insurances')}>Insurances</Link>
                        </ListGroup.Item> */}

                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'education' ? "#69426f" : "" }}
                                to="/education"
                                className={`nav-link scrollto ${activeNavItem === 'education' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('education')}>Education</Link>
                        </ListGroup.Item>

                        <ListGroup.Item action >
                            <Link style={{ color: activeNavItem === 'AllArticles' ? "#69426f" : "" }}
                                to="/blogs"
                                className={`nav-link scrollto ${activeNavItem === 'AllArticles' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('blogs')}>Articles</Link>
                        </ListGroup.Item>

                    </ListGroup>


                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default NavBar;