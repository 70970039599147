import '../../index.css';


function Gallery(){
    return (
        <section id="gallery" className="gallery">
        <div className="container">
          <div className="section-title">
          </div>
        </div>
  
        <div className="container-fluid">
          <div className="row g-0">
  
            {/* Repeat the following structure for each gallery item */}
            <div className="col-lg-2 col-md-4">
              <div className="gallery-item">
                  <img src="images/heroes/4.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="gallery-item">
                  <img src="aacshPics/1.jpeg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="gallery-item">
                  <img src="images/heroes/2.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="gallery-item">
                  <img src="aacshPics/3.jpeg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="gallery-item">
                  <img src="images/heroes/5.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="gallery-item">
                  <img src="aacshPics/5.jpeg" alt="" className="img-fluid" />
              </div>
            </div>

            
            
            {/* End of gallery item structure */}
  
            {/* Repeat the above structure for other gallery items */}
            
          </div>
        </div>
      </section>
    )
}

export default Gallery