import '../../index.css';


function FrequentlyAsked() {
    return(
        <section id="faq" className="faq section-bg">
      <div className="container">

        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="faq-list">
          <ul>
            <li data-aos="fade-up">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1" style={{ textDecoration: "none" }}>What types of cosmetic surgery do you offer?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                <p>
                We offer a wide range of cosmetic surgery options, including but not limited to, facial rejuvenation, body contouring, breast surgery, and non-surgical treatments.                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="100">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed" style={{ textDecoration: "none" }}>Who are the surgeons at your hospital?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                <p>
                Our surgeons are highly experienced and internationally recognized professionals in the field of cosmetic surgery. They hold certifications from various international cosmetic surgery boards                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed" style={{ textDecoration: "none" }}>How long does it take to recover from cosmetic surgery?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                <p>
                Recovery time can vary depending on the type of surgery and the individual's health. However, we offer post-operative care to ensure a smooth recovery.                  </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed" style={{ textDecoration: "none" }}>Do you provide financing options?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                <p >
                Yes, we provide financing options to make cosmetic surgery more accessible. We offer a range of flexible payment plans to suit different budgets.             
                   </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed" style={{ textDecoration: "none" }}>How can I schedule a consultation?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                <p>
                You can schedule a consultation by contacting us through our website, via phone, or by visiting our hospital in Dubai.
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-6" className="collapsed" style={{ textDecoration: "none" }}>Do you cater to international patients?<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-6" className="collapse" data-bs-parent=".faq-list">
                <p>
                Yes, we welcome patients from all over the world. We can assist with visa processes, travel arrangements, and provide multilingual support.   
               </p>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </section>
    )

}

export default FrequentlyAsked