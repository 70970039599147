import React, { useEffect } from 'react';
import { FaCheck } from "react-icons/fa";


function ThankYouPage() {
    return (
        <div style={{ marginTop: "150px", textAlign: "center" }}>
            <h1 style={{ fontSize: "6.25rem", fontWeight: "700", textTransform: "uppercase", color:"#69426f" }}>THANK YOU!</h1>
            <FaCheck size={120} color='#69426f'/>
            <p style={{ fontSize: "1.5rem" }}>Your appointment request has been sent successfully. Thank you!</p>
        </div>
    );
}
export default ThankYouPage