import Counter from "../Counter/Counter"
import About from "../About/About"
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import Testimonials from "../Testimonials/Testimonials";
import FrequentlyAsked from "../FrequentlyAsked/FrequentlyAsked";
import { Helmet } from 'react-helmet';
import '../Insurances/Insurances.css';

function AboutUs() {

    return (
        <div>
            <Helmet>
                <title>Elite Plastic and Cosmetic Surgery Hospital In Dubai | About Us
                </title>
                <meta name="description" content="AACSH is equipped with the latest technologies and has the best plastic and cosmetic surgeons and specialists in Dubai." />
                {/* Add more meta tags if needed */}
            </Helmet>
            <Row style={{ marginTop: "100px" }}><About /></Row>
            {/* <Row><Counter /></Row> */}
            {/* <Row><Testimonials /></Row> */}
          
            <Row><FrequentlyAsked /></Row>

        </div>
    )
}

export default AboutUs