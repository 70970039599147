import '../../index.css';


function Hero(){
    return (
        <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <h1 style={{color : "#69426f"}}>Welcome to AACSH</h1>
          <h2 style={{color : "#69426f"}}>Where Beauty Comes To Life</h2>
          
        </div>
      </section>
    )
}

export default Hero