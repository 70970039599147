import { Link } from 'react-router-dom';
import '../../index.css';
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap

const logo = process.env.PUBLIC_URL + '/logo.svg';
const jci = process.env.PUBLIC_URL + '/jci.png';


function Footer({ onComponentChange }) {

  const handleDownload = () => {
    // Replace 'your-pdf-file.pdf' with the path to your PDF file
    const pdfUrl = process.env.PUBLIC_URL + 'pdf/brochure.pdf';

    // Create an anchor element
    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    anchor.download = 'your-pdf-file.pdf'; // Set the file name for download
    anchor.click(); // Trigger the click event to initiate download
  };

  return (
    <footer id="footer">

      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6 ">
              <h4>Address</h4>
              <p>
                Building 73 <br />
                Umm Hurair 2, Dubai Healthcare City, Dubai<br />
                United Arab Emirates <br /><br />
                <strong>Phone:&nbsp;</strong>   +971 442 37600<br />
                <strong>Email:&nbsp;&nbsp;&nbsp;</strong>   info@aacsh.com<br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4 style={{ marginLeft: "5px" }}>Useful Links</h4>
              <ul>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('home')} to="/">Home</Link></li>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('about')} to="/about-us">About us</Link></li>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('offers')} to="/our-specialties">Specialities</Link></li>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('doctors')} to="/doctors">Doctors</Link></li>



              </ul>
            </div>


            <div className="col-lg-3 col-md-6 footer-links">
              <h4></h4>

              <ul style={{ marginTop: "25px" }}>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('careers')} to="/careers">Careers</Link></li>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('privacy')} to="/privacy">Privacy Policy</Link></li>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('insurances')} to="/insurances">Insurances</Link></li>
                <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('education')} to="/education">Education</Link></li>
                {/* <li><i style={{ color: '#69426f' }} className="bx bx-chevron-right"></i> <Link style={{ textDecoration: "none" }} onClick={() => onComponentChange('pdf')} to="/pdf">download</Link></li> */}

              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <form action="" method="post">
                <input style={{ border: "red" }} type="email" name="email" /><input type="submit" style={{ backgroundColor: '#69426f' }} value="Subscribe" />
              </form>
              <div style={{ marginTop: "25px" }}>
                <Row>
                  <Col>
                    <h3><img className="img-fluid" src={jci} style={{ width: "100px", height: "100%", objectFit: "cover" }} /></h3>
                  </Col>
                  <Col>
                    <h3 style={{ visibility: "hidden" }}><img className="img-fluid" src="/QR.png" style={{ width: "100px", height: "100%", objectFit: "cover" }} /></h3>
                  </Col>
                </Row>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">

        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            &copy; Copyright 2024 <strong><span>AACSH</span></strong>. All Rights Reserved
          </div>

        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://www.instagram.com/aacshospital/" style={{ backgroundColor: '#69426f' }} className="instagram"><i className="bx bxl-instagram"></i></a>
          <a href="https://www.tiktok.com/@aacsh.hospital" style={{ backgroundColor: '#69426f' }} className="linkedin"><i className="bx bxl-tiktok"></i></a>
          <a href="https://www.facebook.com/AACSHospital/" style={{ backgroundColor: '#69426f' }} className="facebook"><i className="bx bxl-facebook"></i></a>
          <a href="https://www.linkedin.com/company/american-academy-of-cosmetic-surgery-hospital" style={{ backgroundColor: '#69426f' }} className="linkedin"><i className="bx bxl-linkedin"></i></a>
          <a href="https://twitter.com/AACSHospital" style={{ backgroundColor: '#69426f' }} className="twitter"><i className="bx bi-twitter-x"></i></a>

        </div>
      </div>
    </footer>
  )
}

export default Footer