function contactMenu() {
    return (
        <div style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: '999' }}>
            <button style={{ padding: '10px 20px', margin: '10px', cursor: 'pointer', backgroundColor: '#4CAF50', color: '#fff', borderRadius: '5px', fontSize: '16px', border: 'none' }}>
                Bottom Right
            </button>
            <button style={{ padding: '10px 20px', margin: '10px', cursor: 'pointer', backgroundColor: '#2196F3', color: '#fff', borderRadius: '5px', fontSize: '16px', border: 'none' }}>
                Bottom Left
            </button>
        </div>
    )

}
export default contactMenu