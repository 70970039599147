import '../../index.css';
import React, { useState, useEffect } from 'react';


function About() {

    useEffect(() => {
        // Load Google Analytics script
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H0L89HEMKP';
        script.async = true;
        document.head.appendChild(script);

        // Initialize Google Analytics
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-H0L89HEMKP');
        };

        // Cleanup script tag on component unmount
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    return (
        <section id="about" className="about">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-5 col-lg-6  d-flex justify-content-center align-items-stretch position-relative">
                        <img src="aacshPics/Hero.jpg" alt="" className="img-fluid" />
                    </div>

                    <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch py-8 px-lg-5">
                        <h3>State-of-the-Art Hospital</h3>
                        <p>The American Academy of Cosmetic Surgery Hospital is a medical facility that operates in a highly organized, efficient, and skilled manner, with a focus on providing the best possible care to patients.</p>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-atom"></i></div>
                            <h4 className="title"><a style={{ textDecoration: "none" }} href="">Mission</a></h4>
                            <p className="description">
                                "To establish a comprehensive medical and cosmetic facility that accommodates the needs of the region, by providing optimal care, services, training, and public awareness. Ensure the highest quality of care is delivered to our clients, by hiring the most qualified staff, and purchasing high-end medical equipment.".
                            </p>
                        </div>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a style={{ textDecoration: "none" }} href="">Vision</a></h4>
                            <p className="description">
                                "The American Academy of Cosmetic Surgery Hospital will be the world-wide reference in Cosmetic Surgery with its integrated facilities clinically and educationally, and global model of excellence to reach international
                                recognition and to meet the international standard of quality".
                            </p>
                        </div>

                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-gift"></i></div>
                            <h4 className="title"><a style={{ textDecoration: "none" }} href="">Values</a></h4>
                            <p className="description">
                                <strong>Efficiency:</strong> Coordinating all program operations in order to optimize efficiencies, reduce duplication, and maintain quality while optimally positioning the organization for managed care opportunities.<br /><br />
                                <strong>Profession Excellence:</strong> Meeting the needs and exceeding the expectations of those served.<br /><br />
                                <strong>Integrity:</strong> Maintaining open communication and relationship of trust, respect, and concern for all.<br /><br />
                                <strong>Innovation:</strong> Fostering personal innovation and organizational creativity; and participating in and carrying on continuous educational activities.<br /><br />
                                <strong>Concern and Respect for the Individual:</strong> Regarding all people as persons of dignity and worth.
                            </p>
                        </div>



                        <h3 style={{ marginTop: "35px" }}>Our Credentials</h3>

                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-badge"></i></div>
                            <h4 className="title"><a style={{ textDecoration: "none" }} href="">Extremely Skilled Medical Professionals:</a></h4>
                            <p className="description">Our doctors have extensive experience in their professions and are recognized globally through Board Certifications and accreditations.</p>
                        </div>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-laptop"></i></div>
                            <h4 className="title"><a style={{ textDecoration: "none" }} href="">Superior Technology:</a></h4>
                            <p className="description">We invest drastically in cutting-edge medical technologies, propelling American Academy Of Cosmetic Surgery Hospital Dubai to the forefront of revolutionary healthcare while significantly improving medical outcomes.</p>
                        </div>

                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-health"></i></div>
                            <h4 className="title"><a style={{ textDecoration: "none" }} href="">Information on Health:</a></h4>
                            <p className="description">We take corporate social responsibility seriously. Our CSR projects go beyond simple healthcare education and include community health checks and wellness programs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}


export default About