// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container .carousel-control-prev,
.carousel-container .carousel-control-next {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/Articles.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;AACf","sourcesContent":[".carousel-container .carousel-control-prev,\r\n.carousel-container .carousel-control-next {\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
