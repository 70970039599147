import '../../index.css';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import { Link } from 'react-router-dom';


function DoctorCard(props) {
  // console.log(props.qualifications[0])
  const [show, setShow] = useState(false);
  const [selectedDoc, setSelectedOffer] = useState(props);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <div>
      <Link style={{ textDecoration: "none" }} onClick={() => props.onComponentChange('doctors', "", props)} to={{
        pathname: `/doctors/${props.name.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')} `,

        state: { doctor: props }
      }}>
        <div className="member d-flex align-items-start" style={{ maxHeight: "280px", boxShadow: "5px 5px 15px #a880ad" }} onClick={handleShow}>
          <div className="pic"><img src={props.image} style={{ objectFit: "contain", height: "200px" }} className="img-fluid" alt="" /></div>
          <div className="member-info" style={{marginTop: "30px"}}>
            <h4 style={{ color: "#69426f" }}>{props.name}</h4>
            <span>
              {props.option && <>{props.option} <br /></>}
              {props.speciality}
            </span>
            {props.check == true && (
              <div style={{ marginTop: "15px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h6 style={{ fontWeight: "600", fontSize: "15px", marginRight: "5px" }}>Nationality:</h6>
                  <h6 style={{ fontSize: "15px", fontWeight: "normal" }}>{props.nat}</h6>
                </div>
                <div style={{ marginTop: "5px" }}>
                  {/* <h6 style={{ fontWeight: "600", fontSize: "15px", marginRight: "5px" }}>Languages:</h6> */}
                  {/* <ul style={{ paddingLeft: "20px" }}>
                    {props.lang && props.lang.map(
                      (l, index) => (
                        <li key={index}>{l}</li>
                      )
                    )}
                  </ul> */}
                  {/* <a  style={{ textDecoration: "none" }} href="#appointment" className="appointment-btn scrollto"><span className="d-none d-md-inline" >Book Now </span></a> */}

                </div>
              </div>
            )}
          </div>
        </div>


        {/* <Modal show={show} onHide={handleClose} className='modal-lg'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <section id="doctors" className="doctors">
            <div className="container">
              <div className='row'>
                <div class="member d-flex align-items-start">
                  <div class="pic"><img src={props.image} class="img-fluid" alt="" /></div>
                  <div class="member-info">
                    <h4 style={{ color: "#69426f" }}>{props.name}</h4>
                    <span>{props.speciality}</span>
                    <p><b>Nationality:</b> {props.nat}</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginRight: "10px" }}><b>Languages:</b></p>
                      {props.lang.map((lang, index) => (
                        <p key={index} style={{ display: "inline-block", marginRight: "10px" }}>{lang},</p>
                      ))}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="about" className="about" style={{ marginTop: "-100px" }}>
            <div className="container-fluid">
              <div className="row">

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-fingerprint"></i></div>
                  <h4 className="title"><a style={{ textDecoration: "none" }} >Profile Summary</a></h4>
                  <p className="description">{props.summary}</p>
                </div>

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-briefcase"></i></div>
                  {props.cc && (
                    <div>
                      <h4 className="title">Core Competencies</h4>
                      {Object.entries(props.cc).map(([competency, items]) => (
                        items && Array.isArray(items) && ( // Check if items is an array
                          <div key={competency}>
                            <p className="description">{competency}</p>
                            <ul>
                              {items.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </div>
                        )
                      ))}
                    </div>
                  )}
                </div>

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-badge"></i></div>
                  <h4 className="title"><a style={{ textDecoration: "none" }}>Qualifications</a></h4>
                  {props.qualifications && props.qualifications.map(
                    (q, index) => (
                      <p className="description" key={index}>{q}</p>
                    )
                  )}
                </div>

              </div>
            </div>
          </section>
        </Modal.Body>

      </Modal> */}
      </Link>
    </div>
  )
}

export default DoctorCard