// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-control-prev-icon {
    background-color: #69426f; /* Change this to the desired background color */
  }
  
  .carousel-control-next-icon {
    background-color: #69426f; /* Change this to the desired background color */
  }`, "",{"version":3,"sources":["webpack://./src/components/Testimonials/Testimonials.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,gDAAgD;EAC7E;;EAEA;IACE,yBAAyB,EAAE,gDAAgD;EAC7E","sourcesContent":[".carousel-control-prev-icon {\r\n    background-color: #69426f; /* Change this to the desired background color */\r\n  }\r\n  \r\n  .carousel-control-next-icon {\r\n    background-color: #69426f; /* Change this to the desired background color */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
