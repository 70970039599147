import '../../index.css';
import Modal from 'react-bootstrap/Modal';
import Appointment from '../Appointment/Appointment';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const logo = process.env.PUBLIC_URL + '/aacshPics/8.jpeg';


function Offers({ onComponentChange }) {

  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-H0L89HEMKP';
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-H0L89HEMKP');
    };

    // Cleanup script tag on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const data = {
    'PEDIATRIC DENTISTRY': {
      '': [
        'Pediatric dentistry provides comprehensive oral health management for children from infancy to adolescence, and strives to help them maintain healthy smiles throughout their lives. Our specialized pediatric dental services are designed to meet the special needs of our young patients and provide a  child-friendly environment. American Academy of Cosmetic Surgery Hospital focuses on preventive treatments such as regular checkups, cleanings, fluoride treatments, and  sealants to prevent tooth decay. Our team is skilled in behavior management techniques to make dental visits enjoyable and stress-free and promote positive attitudes toward oral health. We value education and teach children and parents the importance of proper oral hygiene and healthy eating habits. Early detection and treatment of dental problems such as cavities, misaligned teeth, and growth problems is our top priority. We offer specialized treatments tailored to your child, including fillings, veneers, and space maintenance, and we also provide specialized care for dental emergencies. We monitor your child\'s tooth and jaw development to ensure proper tooth alignment and growth, and work with your orthodontist as needed. Our goal is to provide excellent, compassionate dental care that supports the overall health of our young patients.',
      ],


    },
    'IV THERAPY': {
      'IV therapy, commonly known as intravenous therapy, is the practice of administering nutrients and fluids directly into the bloodstream for prompt absorption and usage by the body. IV therapy is the quickest way for transferring nutrients throughout the body because it bypasses the digestive system and travels straight to the organs, resulting in a 90-100% absorption rate.': [
        '',
      ],
      'Nicotinamide Adenine Dinucleotide': [
        'NAD+  is an important and one of the most essential molecules "co-enzyme" are found In each living cell in our body. It is involved in cellular metabolism and energy Production. The body requires Nad+ to convert nutrients to energy and to regulate and maintain many cellular processes. NAD+ is the cornerstone of maintaining healthy internal organs and a healthy neurological system.',
      ],
      'Benefits Of Nad+:': [
        'Increase energy ',
        'General wellness DNA repair and repairs damaged cells ',
        'Protects brain cells & improves mental health.',
        'Anti-aging (slows down aging) muscle protection & reduces pain.',
        'Addiction recovery.',
        'Enhances athletic performance and weight loss.',
        'Supports cellular functions and is one of the best anti-oxidants.',
        'Memory/concentration issues'

      ],

    },
    'FUE HAIR TRANSPLANT': {
      'Discover Advanced FUE Hair Transplant at The American Academy of Cosmetic Surgery Hospital in Dubai': [
        'Welcome to The American Academy of Cosmetic Surgery Hospital in Dubai, a leading destination for cutting-edge cosmetic procedures that empower you to embrace your beauty with confidence. Among our suite of exceptional services, the Follicular Unit Extraction (FUE) hair transplant stands out as a revolutionary solution for hair loss, offering natural-looking results with minimal downtime. Here, we delve into the world of FUE hair transplants, highlighting how this advanced technique can redefine your appearance and enhance your self-esteem.',
      ],
      'What is FUE Hair Transplant?': [
        'Follicular Unit Extraction (FUE) is a state-of-the-art hair transplant technique designed to restore hair density and cover areas of thinning or baldness without leaving a noticeable scar. Unlike traditional methods, FUE involves the extraction of individual hair follicles from the donor area (typically the back of the head) using a precise microsurgical extraction instrument. These follicles are then carefully implanted into the target areas, creating a natural-looking hairline and fuller hair coverage.',
      ],
      'Why Choose FUE Hair Transplant at The American Academy of Cosmetic Surgery Hospital?': [
        'Expert Team of Surgeons: Our hospital takes pride in housing a team of globally accredited cosmetic surgeons who specialize in the latest hair restoration techniques. With years of experience and a commitment to excellence, our surgeons ensure that each FUE hair transplant procedure is tailored to meet the unique needs and aesthetic goals of our clients.',
        ,
        'Cutting-edge Technology: We leverage advanced technology and innovative techniques to enhance the precision and efficiency of the FUE hair transplant process. Our state-of-the-art equipment minimizes trauma to the scalp, accelerates the healing process, and maximizes the survival rate of the transplanted hair follicles, ensuring outstanding results.',
        'Minimally Invasive Procedure: One of the key advantages of FUE hair transplant is its minimally invasive nature. The procedure involves no stitches, staples, or significant scarring, allowing for a quicker and more comfortable recovery. Patients can typically resume their normal activities shortly after the procedure, making it a convenient option for those seeking significant aesthetic improvements with minimal interruption to their daily lives.',
        'Personalized Care and Support: At The American Academy of Cosmetic Surgery Hospital, we understand that every individual\'s journey to hair restoration is unique. From the initial consultation to post-procedure care, our dedicated team provides personalized support, guiding you through each step of the process with compassion and professionalism.',
        'Transform Your Appearance Today: If you\'re grappling with hair loss and seeking a solution that offers natural, long-lasting results, the FUE hair transplant at The American Academy of Cosmetic Surgery Hospital in Dubai may be the perfect option for you. Embrace the opportunity to rejuvenate your appearance and boost your confidence with this advanced cosmetic procedure.',

      ],
    },
    'COSMETIC & PLASTIC SURGERY': {
      'The Face': [
        'Fat Transfer to the face',
        'Face lift',
        'Eyelids (Blepharoplasty)',
        'Brow lift',
        'Neck Lift',
        'Double Chin correction',
        'Rhinoplasty',
        'Cheek Augmentation',
        'Dimples creation',
        'Buccal Fat Removal',
        'Otoplasty (Ear Reshaping)',
        'Dermabrasion',
        'Permanent fillers removal',
        'Mole and cysts removal',
        'Lip lift',
        'Lip reduction',
        'Neck Bands "Platysma” Correction',
        'Stem Cells',
      ],
      'The Body': [
        'Body contouring / Body reshaping',
        'Upper Body Lift',
        'Lower Body Lift',
        'Liposuction',
        'Arm lift',
        'Abdominoplasty (Tummy tuck)',
        'Lipoabdominoplasty (Advanced tummy tuck)',
        'Thigh lift',
        'Fat Hump Removal (Buffalo Hump)',
        'Calf improvement by implant/fat graft',
        'Buttock Augmentation',
        'Gluteoplasty (Butt lift)',
        'Brazilian butt lift',
        'Fat grafting',
        'High definition liposculpture & vaser',
        'J Plasma',
        'Post Bariatric Lift',
        'Mommy Makeover',
      ],
      'The Breast': [
        'Breast augmentation with implants or fat grafting',
        'Breast lift with or without implants',
        'Breast reduction',
        'Breast implants exchange',
        'Nippleplasty',
        'Areola reduction',
        'Breast reconstruction post mastectomy',
        'Male breast reduction (gynecomastia)',
        'Pectoral implants',
      ],
      'Plastic, Reconstructive & Hand Surgery': [],
      'Post-Burn Deformity and Scar Corrections': [],
    },
    'DERMATOLOGY & ANTI AGING': {
      'The Face': [
        'Injectables',
        'HA Fillers',
        'Collagen biostimulators (Sculptra, Radiesse)',
        'Skin Boosters',
        'Profhilo',
        'Profhydro',
        '3D facelift',
        'Mesotherapy (Amber, Aminoacids, Peptides, HA)',
        'Botox',
        'Thread Lift',
        'Texas - jawline contouring',
        'Non-surgical nose reshaping',
        'Platelet-rich plasma therapy (PRP)',
        'Nefertiti - neck rejuvenation',
        'Chemical peels',
        'Snow White treatment for pigmentation',
        'Mona Lisa - smile enhancement',
        'Dermapen skin rejuvenation',
        'Exosomes',
      ],
      'The Body / Skin': [
        'Laser tattoo Removal',
        'Butt Fillers',
      ],
      'General Dermatology': [
        'Examination and treatment of skin conditions',
        'Electrocauterization',
        'Birthmark removal',
        'Treatment of vascularities',
        'Dermoscopy mole examination / excision',
        'Scar treatment',
      ],
      'Anti-aging / Regenetive Medicine': [
        'Stem Cells',
      ],
    },
    'HAIR LOSS': [
      'Hair loss treatment (Crown on your head)',
      'Mesotherapy for hair',
      'Revage laser therapy',
      'PRP hair treatment (Platelet rich plasma)',
      'Dry or oily hair and scalp',
      'Excessive scalp itching',
      'Damage from coloring, chemicals, or straightening of the hair',
      'Ketox treatment',
      'Rigenera ™ Hair loss treatment',
      'Hair loss treatment "Crown on your head"',
      'Mesotherapy for hair',
      'Revage laser therapy',
      'PRP hair treatment (Platelet rich plasma)',
    ],
    'MEDICAL SPA': {
      'The Face': [
        'HydraFacial MD',
        'Cleopatra facial',
        'Al Shehana signature facial',
        'Medical facial (customized acne treatment)',
        'Red carpet facial',
        'Stamping facial',
        'Derma roller facial',
        'Microdermabrasion',
        'Caci quantum face lift',
        'Ketox hair treatment',
        'Hydra Gold',
        'White Linen Facial',
        'The Signature Facial',
        'Crystal Peel',
        'Exilis Face',
        'Laser hair removal',
        'Laser tattoo removal',
        'Treatment for face rejuvenation',
        'Treatment of spider & varicose veins',
        'Rioblush - carboxy therapy for dark circles and localized fat adiposits',
        'Mesotherapy (Stretch marks, Fat dissolving, cellulite)',
        'Cellina fractionated radiofrequency skin tightening',
        'Treatment of axillary & palms-hyperhidrosis',
      ],
      'The Body': [
        'CoolSculpting non-surgical fat reduction',
        'CACI quantum',
        'Body wrap',
        'Body massage',
        'Lymphatic drainage massage',
        'Skin pigmentation body treatments',
        'Exilis Body',
        'X-WaveTM',
        'Mag Sculpt',
      ],
      'Post Surgical Rehabilitation': [
        'Post liposuction surgery treatment',
        'Post rhinoplasty surgery treatment packages',
        'Post tummy tuck surgery treatment packages',
        'Post body lift surgery treatment Packages',
        'Post augmentation surgery treatment packages',
      ],
    },
    'WEIGHT LOSS & NUTRITION': {
      'Weight Loss Surgery': [
        'Gastric sleeve',
        'Gastric balloon',
        'Mini gastric bypass',
        'Classic (Roux en y) gastric bypass',
        'Duodena I switch',
        'Gastric plication',
        'Endoscopic sleeve gastroplasty',
      ],
      'Nutrition': [
        'Nutrition support',
        'Weight management',
        'Diabetes (including gestational diabetes)',
        'Cholesterol and triglyceride lowering',
        'Irritable bowel syndrome (IBS)',
        'Low FODMAP diet for IBS',
        'Nutrition support in: Oncology, Stroke, Neuro-rehabilitation, Pre and post-surgery nutrition, Maternal diet and nutrition, Pre and post-bariatric surgery, Endocrinology Disorders of glucose-insulin Metabolism and body weight Disorders of blood pressure & lipids metabolism Disorders of bone metabolism & parathyroid glands Disorders of thyroid Disorders of hypophysis, adrenals, ovary and testis glands Diabetes, metabolic syndrome, obesity & insulin resistance, also in pregnancy Polycystic Ovary Syndrome, acne, hirsutism, altered menses and erectile dysfunction Thyroid goiter, nodules and thyroiditis, hyper/hypo-thyroidism, also in pregnancy Osteoporosis, vitamin D and calcium abnormalities, hyper/hypo-parathyroidism Pituitary, Adrenals and Gonads Disorders Idiopathic and hormonal hypertension; hyper-cholesterolemia / triglyceridemia',
      ],
    },
    'ENDOCRINOLOGY': [
      'Disorders of glucose-insulin Metabolism and body weight',
      'Disorders of blood pressure & lipids metabolism',
      'Disorders of bone metabolism & parathyroid glands',
      'Disorders of thyroid',
      'Disorders of hypophysis, adrenals, ovary and testis glands',
      'Diabetes, metabolic syndrome, obesity & insulin resistance, also in pregnancy',
      'Polycystic Ovary Syndrome, acne, hirsutism, altered menses and erectile dysfunction',
      'Thyroid goiter, nodules and thyroiditis, hyper/hypo-thyroidism, also in pregnancy',
      'Osteoporosis, vitamin D and calcium abnormalities, hyper/hypo-parathyroidism',
      'Pituitary, Adrenals and Gonads Disorders',
      'Idiopathic and hormonal hypertension; hyper-cholesterolemia / triglyceridemia',
    ],
    'COSMETIC GYNECOLOGY': [
      'Laser Vaginal Tightening',
      'Laser stress urinary incontinence treatment',
      'Laser vulvar and inner thighs whitening',
      'Vulvar whitening using serums and chemical peelings',
      'Surgical labiaplasty and minor posterior colporraphy',
      'Early detection, prevention and treatment of age-related gynecological conditions (vaginal atrophy)',
      'G spot - O Shot PRP Treatment',
      'Labial Fillers',
    ],
    // 'PSYCHOLOGY': [
    //   'Comprehensive Psychological Assessment',
    //   'Individual Counseling and Therapy',
    //   'Stress Management and Coping Skills',
    //   'Psychological Support for Chronic Illness',
    //   'Career Counseling and Guidance',
    // ],
    "ORTHOPEDIC": {
      "Fracture Management": [
        "Expert management of pelvis, acetabulum, spine, and extremity fractures, including complex and pediatric cases.",
        "Specialized care for open, complex, and intra-articular fractures, and those associated with vascular or neurological complications."
      ],
      "Joint Replacement": [
        "Application of the latest trends in hip, knee, and ankle replacement surgeries.",
        "Comprehensive counseling, advice, and support from our team of orthopedic consultants.",
        "Close follow-up and support from our physiotherapists and occupational therapists for optimal recovery and pain management."
      ],
      "Musculoskeletal Tumor Care": [
        "Management of benign and malignant musculoskeletal tumors across different age groups.",
        "Complete workup, diagnosis, and tailored treatment plans for musculoskeletal malignancies."
      ],
      "Soft Tissue Procedures": [
        "Expert management of tendon releases, nerve surgeries, and bone infections.",
        "Specialized care for all types of tendons, peripheral nerves, and bone-related conditions."
      ],
      "Neurospine Care": [
        "Holistic management of disc prolapse, spine fusion, and spinal deformities.",
        "Specialized care for conditions like spondylolisthesis and kyphoscoliosis."
      ],
      "Foot & Ankle Services": [
        "Management of diabetic and Charcot foot and ankle conditions, including tailored physiotherapy and orthotic support.",
        "Expert care for ligament repair, bunions, deformities, and conditions like plantar fasciitis and flat feet."
      ],
      "Pediatrics and Sports Injury Management": [
        "Specialized care for congenital and developmental extremity deformities in children.",
        "Management of sports injuries, including arthroscopic procedures for shoulder, hip, knee, and ankle conditions."
      ]

    },

  };

  const descs = {
    "FUE HAIR TRANSPLANT": { desc: "FUE hair transplant is an advanced procedure that offers a natural-looking solution to thinning hair, restoring confidence with minimal downtime. Experience the latest in hair restoration technology and reclaim a fuller, thicker head of hair. " },
    "COSMETIC & PLASTIC SURGERY": { desc: "Our expert surgeons combine cutting-edge techniques with personalized care to help you achieve your aesthetic goals. We offer a wide range of procedures, from breast augmentation and facelifts to liposuction and rhinoplasty, all performed by highly skilled and experienced surgeons." },
    "DERMATOLOGY & ANTI AGING": { desc: "Whether you're looking to rejuvenate your complexion, reduce the signs of aging, or achieve a flawless glow, we're here to guide you on your journey to radiant, youthful skin." },
    "HAIR LOSS": { desc: "Hair loss affects millions worldwide, but understanding its causes and treatments can be a game-changer. From stress and genetics to diet and lifestyle, explore the factors contributing to hair loss and discover expert tips for regaining confidence and healthy locks.  " },
    "MEDICAL SPA": { desc: "Step into relaxation and rejuvenation at our medical spa, where science meets serenity. Indulge in luxurious treatments designed to enhance both your inner wellness and outer beauty. From advanced skin care therapies to stress-relieving massages, our expert team is dedicated to providing personalized care tailored to your unique needs." },
    "WEIGHT LOSS & NUTRITION": { desc: "Our expert team is dedicated to guiding you towards sustainable lifestyle changes, personalized meal plans, and ongoing support to help you achieve your goals. Plus, explore cutting-edge options like bariatric surgery procedures for effective, long-term weight loss solutions. " },
    "ENDOCRINOLOGY": { desc: "Explore the inner workings of glands like the thyroid, adrenal, and pituitary, and discover how they impact your health and well-being. From managing diabetes to addressing hormonal imbalances, endocrinology holds the key to understanding and optimizing your body's functions." },
    "COSMETIC GYNECOLOGY": { desc: "Every woman deserves to feel confident and comfortable in her own body.We offer a comprehensive range of surgical and non-surgical treatments specifically designed to address the unique needs and concerns of women seeking cosmetic gynecology procedures.Our team of highly skilled and experienced cosmetic gynecologists are dedicated to providing the highest standard of care in a compassionate and supportive environment." },
    // "PSYCHOLOGY": { desc: "We offer personalized counseling and therapy sessions tailored to your unique needs, guiding you on a journey towards self-discovery and growth. Whether you're facing challenges with anxiety, depression, or relationship issues, our compassionate approach empowers you to overcome obstacles and thrive. Take the first step towards a happier, more fulfilling life today." },
    "ORTHOPEDIC": { desc: "Welcome to our comprehensive orthopedic services, where your comfort and mobility are our top priorities. From sports injuries to joint replacements, our team of skilled orthopedic specialists is here to provide personalized care tailored to your unique needs. Whether you're recovering from an injury or seeking relief from chronic pain, trust us to help you get back to living your life to the fullest." },
    "IV THERAPY": { desc: "IV therapy, commonly known as intravenous therapy, is the practice of administering nutrients and fluids directly into the bloodstream for prompt absorption and usage by the body. IV therapy is the quickest way for transferring nutrients throughout the body because it bypasses the digestive system and travels straight to the organs, resulting in a 90-100% absorption rate" },
    "PEDIATRIC DENTISTRY": { desc: "We offer specialized treatments tailored to your child, including fillings, veneers, and space maintenance, and we also provide specialized care for dental emergencies. We monitor your child's tooth and jaw development to ensure proper tooth alignment and growth, and work with your orthodontist as needed." }

  };

  


  return (
    <div>
      <Helmet>
        <title>Plastic Surgery | Cosmetic Surgery | Laser Treatment | Hair Transplantation</title>
        <meta name="description" content="We specialize in a wide range of procedures, including surgical, non-surgical, laser, weight loss and nutrition, and hair transplants." />
        {/* Add more meta tags if needed */}
      </Helmet>
      <section id="departments" className="departments" style={{ marginTop: "100px" }}>
        <div className="container">

          <div className="section-title">
            <h2 style={{ color: "#2c4964" }}>Our Specialities</h2>

          </div>

          <div className="row justify-content-center">
            {Object.entries(data)
              .sort(([categoryA], [categoryB]) => categoryA.localeCompare(categoryB)) // Sort categories alphabetically
              .map(([category, subcategories], index) => (
                <div className='col-lg-4' style={{ marginBottom: "50px" }}>

                  <Card style={{ width: '22rem', minHeight: '38rem', maxHeight: '25rem', textAlign: "center" }}>
                    <Card.Img variant="top" src={`images/services/${category}.jpg`} style={{ objectFit: "cover", width: "100%", height: "200px" }} />
                    <Card.Body>
                      <Card.Title style={{ color: "#69426f" }}>{category}</Card.Title>
                      <Card.Text>
                        {descs[category].desc}
                      </Card.Text>


                      {/* <div style={{marginTop: "auto"}}>
                        <Link to={`/Speciality/${category.replace(/\s+/g, '')}`} onClick={() => onComponentChange('Speciality', { category, subcategories } , '', '')}>

                          <a style={{ textDecoration: "none" }} href="#appointment" className="appointment-btn scrollto">
                            <span className="d-none d-md-inline">View More</span>
                          </a>
                        
                      </div> */}

                    </Card.Body>
                    <Card.Footer className="text-muted" style={{ backgroundColor: "#69426f" }}>
                      <Link
                        to={`/specialties/${category.toLowerCase().replace(/&/g, 'and').replace(/\s+/g, '-')}`}
                        onClick={() => onComponentChange('our-specialties', { category, subcategories }, '', '')}>

                        <Button variant="primary" style={{ borderRadius: "25px", backgroundColor: "#69426f", borderColor: "#69426f" }}>Read More</Button>
                      </Link>
                    </Card.Footer>

                  </Card>

                </div>
              ))}
          </div>



































































          {/* <div className="row gy-4">
            <div className="col-lg-3">
              <ul className="nav  flex-column" style={{ listStyleType: "none" }}>
                {Object.entries(data)
                  .sort(([categoryA], [categoryB]) => categoryA.localeCompare(categoryB)) // Sort categories alphabetically
                  .map(([category, subcategories], index) => (
                    <li key={index} className="nav-item" style={{textTransform: "capitalize"}}>
                      <a
                        className={`nav-link ${index === 0 ? 'active show' : ''}`}
                        data-bs-toggle="tab"
                        href={`#tab-${index + 1}`}
                      >
                        {category}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-lg-9">
              <div className="tab-content">
                {Object.entries(data)
                  .sort(([categoryA], [categoryB]) => categoryA.localeCompare(categoryB)) // Sort categories alphabetically
                  .map(([category, subcategories], index) => (
                    <div key={index} className={`tab-pane ${index === 0 ? 'active show' : ''}`} id={`tab-${index + 1}`}>
                      <div className="row gy-4">
                        <h3 style={{ color: "#2c4964", textTransform: "capitalize" }}>{category}</h3>
                        {Array.isArray(subcategories) ? (
                          <ul>
                            {subcategories.map((item, subIndex) => (
                              <li key={subIndex} style={{textTransform: "capitalize"}}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          <div>
                            {Object.entries(subcategories).map(([subcategory, procedures], subIndex) => (
                              <div key={subIndex}>
                                <h4 style={{marginTop: "15px", textTransform: "capitalize"}}>{subcategory}</h4>

                                {procedures.map((procedure, procedureIndex) => (
                                  <li key={procedureIndex} style={{marginBottom:"5px", textTransform: "capitalize"}}>{procedure}</li>
                                ))}

                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Offers