import React, { useState } from 'react';
import './FloatingContact.css';
import { LuPhone } from "react-icons/lu";
import { LuInstagram } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";

function FloatingContact() {

    function openWhatsApp()
    {
        
    }

    return (
        <div className='modif'>


            <div id="container-floating">
           

                <div className="nd3 nds">
                    {/* <img className="reminder" src="//ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/ic_reminders_speeddial_white_24dp.png" /> */}

                    <a className="reminder" href="tel:6177787312" >
                        <LuPhone size={30} color='white'/>
                    </a>
                </div>

                <div className="nd1 nds">
                    <a className="reminder" href="https://wa.me/+971524233941" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp  size={30} color='white'/>
                    </a>
                </div>

               
            </div>
        </div>
    )


}

export default FloatingContact